// REGULATORS //
// LIST
export const FLOWS_LOG_LIST_REQUEST = "FLOWS_LOG_LIST_REQUEST";
export const FLOWS_LOG_LIST_REQUEST_SUCCESS = "FLOWS_LOG_LIST_REQUEST_SUCCESS";
export const FLOWS_LOG_LIST_REQUEST_ERROR = "FLOWS_LOG_LIST_REQUEST_ERROR";

export const FLOWS_LOG_SIBLINGS_REQUEST = "FLOWS_LOG_SIBLINGS_REQUEST";
export const FLOWS_LOG_SIBLINGS_REQUEST_SUCCESS =
  "FLOWS_LOG_SIBLINGS_REQUEST_SUCCESS";
export const FLOWS_LOG_SIBLINGS_REQUEST_ERROR =
  "FLOWS_LOG_SIBLINGS_REQUEST_ERROR";

export const FLOWS_LOG_GET_REQUEST = "FLOWS_LOG_GET_REQUEST";
export const FLOWS_LOG_GET_REQUEST_SUCCESS = "FLOWS_LOG_GET_REQUEST_SUCCESS";
export const FLOWS_LOG_GET_REQUEST_ERROR = "FLOWS_LOG_GET_REQUEST_ERROR";

export const FLOW_LOG_AUTOCOMPLETE_REQUEST = "FLOW_LOG_AUTOCOMPLETE_REQUEST";
export const FLOW_LOG_AUTOCOMPLETE_REQUEST_SUCCESS =
  "FLOW_LOG_AUTOCOMPLETE_REQUEST_SUCCESS";
export const FLOW_LOG_AUTOCOMPLETE_REQUEST_ERROR =
  "FLOW_LOG_AUTOCOMPLETE_REQUEST_ERROR";

// APPPEND
export const FLOWS_LOG_APPEND_NEXT_REQUEST = "FLOWS_LOG_APPEND_NEXT_REQUEST";
export const FLOWS_LOG_APPEND_NEXT_REQUEST_SUCCESS =
  "FLOWS_LOG_APPEND_NEXT_REQUEST_SUCCESS";
export const FLOWS_LOG_APPEND_NEXT_REQUEST_ERROR =
  "FLOWS_LOG_APPEND_NEXT_REQUEST_ERROR";

export const TEAM_FLOWS_LIST_REQUEST = "TEAM_FLOWS_LIST_REQUEST";
export const TEAM_FLOWS_LIST_REQUEST_SUCCESS =
  "TEAM_FLOWS_LIST_REQUEST_SUCCESS";
export const TEAM_FLOWS_LIST_REQUEST_ERROR = "TEAM_FLOWS_LIST_REQUEST_ERROR";

// GET
export const REGULATORS_LIST_REQUEST = "REGULATORS_LIST_REQUEST";
export const REGULATORS_LIST_REQUEST_SUCCESS =
  "REGULATORS_LIST_REQUEST_SUCCESS";
export const REGULATORS_LIST_REQUEST_ERROR = "REGULATORS_LIST_REQUEST_ERROR";

export const REGULATOR_REQUEST = "REGULATOR_REQUEST";
export const REGULATOR_REQUEST_SUCCESS = "REGULATOR_REQUEST_SUCCESS";
export const REGULATOR_REQUEST_ERROR = "REGULATOR_REQUEST_ERROR";

// SET SELECTED
export const FLOW_REGULATORS_SET_SELECTED = "FLOW_REGULATORS_SET_SELECTED";

// VALVES //
// LIST
export const VALVES_LOG_LIST_REQUEST = "VALVES_LOG_LIST_REQUEST";
export const VALVES_LOG_LIST_REQUEST_SUCCESS =
  "VALVES_LOG_LIST_REQUEST_SUCCESS";
export const VALVES_LOG_LIST_REQUEST_ERROR = "VALVES_LOG_LIST_REQUEST_ERROR";

// GET
export const FLOW_VALVES_GET_REQUEST = "FLOW_VALVES_GET_REQUEST";
export const FLOW_VALVES_GET_REQUEST_SUCCESS =
  "FLOW_VALVES_GET_REQUEST_SUCCESS";
export const FLOW_VALVES_GET_REQUEST_ERROR = "FLOW_VALVES_GET_REQUEST_ERROR";

// SET SELECTED
export const FLOW_VALVES_SET_SELECTED = "FLOW_VALVES_SET_SELECTED";

// SET DETAIL VIEW COMPONENT
export const FLOW_DETAIL_SET_VIEW_COMPONENT = "FLOW_DETAIL_SET_VIEW_COMPONENT";

// SET SELECTED
export const FLOW_SIBLING_SET_SELECTED = "FLOW_SIBLING_SET_SELECTED";

export const FLOWS_LOG_AGGS_REQUEST = "FLOWS_LOG_AGGS_REQUEST";
export const FLOWS_LOG_AGGS_REQUEST_SUCCESS = "FLOWS_LOG_AGGS_REQUEST_SUCCESS";
export const FLOWS_LOG_AGGS_REQUEST_ERROR = "FLOWS_LOG_AGGS_REQUEST_ERROR";

export const FLOWS_LOG_RESET_ASSET = "FLOWS_LOG_RESET_ASSET";
export const FLOWS_LOG_RESET_ASSET_SUCCESS = "FLOWS_LOG_RESET_ASSET_SUCCESS";
export const FLOWS_LOG_RESET_ASSET_ERROR = "FLOWS_LOG_RESET_ASSET_ERROR";

export const FLOWS_SEARCH_SET_ORDER_BY = "FLOWS_SEARCH_SET_ORDER_BY";

export const VALVES_LOG_RESET = "VALVES_LOG_RESET";
export const VALVES_LOG_RESET_SUCCESS = "VALVES_LOG_RESET_SUCCESS";
export const VALVES_LOG_RESET_ERROR = "VALVES_LOG_RESET_ERROR";

export const VALVES_LOG_LIST_RESET = "VALVES_LOG_LIST_RESET";

export const PIPELINE_REQUEST = "PIPELINE_REQUEST";
export const PIPELINE_REQUEST_SUCCESS = "PIPELINE_REQUEST_SUCCESS";
export const PIPELINE_REQUEST_ERROR = "PIPELINE_REQUEST_ERROR";

export const FLOW_LOG_RENAME_ASSET = "FLOW_LOG_RENAME_ASSET";
export const FLOW_LOG_RENAME_ASSET_SUCCESS = "FLOW_LOG_RENAME_ASSET_SUCCESS";
export const FLOW_LOG_RENAME_ASSET_ERROR = "FLOW_LOG_RENAME_ASSET_ERROR";
