<template>
  <div id="app">
    <RcPageLoader>
      <transition name="fade" mode="out-in">
        <component
          :key="layout"
          :class="`rc-${layout} ${routeClass}`"
          :is="layout"
        ></component>
      </transition>
    </RcPageLoader>
    <vue-progress-bar></vue-progress-bar>
    <RcExternalLinkModal />
  </div>
</template>

<script>
const transitionLayout = "transition-layout";
// external link modal
import { mapGetters } from "vuex";
import { GET_TEAM_INFO } from "@/libvue/apps/team/actions";
import { GET_CATEGORIES } from "@/libvue/apps/catalog/categories/actions";
import {
  GET_FAST_CHANNELS,
  GET_FREQUENCY_RULES
} from "@/libvue/apps/fastChannels/actions";
import { ROOT_UPDATE_PAGE_LOADED } from "#/stores/root";

import RcPageLoader from "@/libvue/components/pageLoader/PageLoader.vue";
import RcExternalLinkModal from "@/libvue/components/modal/ExternalLinkModal.vue";

export default {
  name: "App",
  components: {
    RcExternalLinkModal,
    RcPageLoader
  },
  computed: {
    ...mapGetters(["getTeamUID", "roleIsGuest"]),
    layout() {
      return this.$route.meta.layout
        ? this.$route.meta.layout + "-layout"
        : transitionLayout;
    },
    routeClass() {
      return this.$route?.meta?.class || "";
    }
  },
  methods: {
    setRouteFocus() {
      document.getElementById("rcMainWrapper").focus();
    }
  },

  watch: {
    getTeamUID: {
      handler(value) {
        if (value && !this.roleIsGuest) {
          this.$store.dispatch(GET_FREQUENCY_RULES);
          this.$store.dispatch(GET_CATEGORIES);
          this.$store.dispatch(GET_FAST_CHANNELS);
          this.$store.dispatch(GET_TEAM_INFO, value);
        }
      },
      immediate: true
    },
    $route(to, from) {
      if (
        from.params.locale &&
        from.params.locale !== to.params.locale &&
        this.getTeamUID &&
        !this.roleIsGuest
      ) {
        this.$store.dispatch(GET_FREQUENCY_RULES);
        this.$store.dispatch(GET_CATEGORIES);
        this.$store.dispatch(GET_FAST_CHANNELS);
        this.$store.dispatch(GET_TEAM_INFO, this.getTeamUID);
      }
    }
  },

  mounted() {
    this.$Progress.finish();
  },
  created() {
    this.$Progress.start();
    this.$store.dispatch(ROOT_UPDATE_PAGE_LOADED, false);

    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      this.$store.dispatch(ROOT_UPDATE_PAGE_LOADED, false);

      next();
    });
    this.$router.afterEach((to, from) => {
      this.$Progress.finish();
      this.$store.dispatch(ROOT_UPDATE_PAGE_LOADED, true);

      this.$nextTick(() => this.setRouteFocus());

      // check if previous page was an error page
      // trigger toast notification to user
      // ex:  "/en/400?code=social-fail&msg="Domain not allowed"&provider=google

      if (from.name == "market.status.code") {
        this.$notify.danger(
          from.query.msg
            ? this.$t(`static.loginError.${from.query.msg}`)
            : "An error occured"
        );
      }
    });
  }
};
</script>
