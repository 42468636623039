<template>
  <header role="banner">
    <div class="container-fluid">
      <nav
        class="navbar rc-main-navigation navbar-dark"
        :aria-label="$t(`a11y.nav.main`)"
      >
        <!--Toggle bar (Hamburger) if authentificated and member of a team-->
        <b-navbar-toggle
          target="rcNavSidebar"
          class="btn rc-btn-icon"
          v-if="isAuthenticated && getTeamUID && !roleIsGuest"
        >
          <RcIcon name="menu" scale="2.2" title="Menu"></RcIcon>
        </b-navbar-toggle>

        <!--Logo webtelly if not authentificated-->
        <div class="navbar-brand" v-else>
          <img src="/img/lg_webtelly.svg" :alt="$t(`nav.logo_go_to_home`)" />
        </div>

        <!--Breadcrumb-->
        <template
          v-if="breadcrumbs && isAuthenticated && getTeamUID && !roleIsGuest"
        >
          <span class="sr-only">{{ $t(`a11y.nav.breadcrumb`) }}</span>

          <ol class="breadcrumb d-none d-lg-flex" v-if="breadcrumbs.crumbs">
            <li class="breadcrumb-item">
              <router-link :to="toHomeUser"> {{ $t("nav.home") }} </router-link>
            </li>

            <li
              v-for="(crumb, index) in breadcrumbs.crumbs"
              class="breadcrumb-item"
              :key="index"
              :class="crumb.active ? 'active' : ''"
            >
              <template v-if="crumb.to || crumb.fnTo">
                <router-link
                  :to="crumb.fnTo ? crumb.fnTo($route) : crumb.to"
                  :class="crumb.disabled ? 'disabled' : ''"
                >
                  {{ crumb.text($route, $store) }}</router-link
                ></template
              >
              <template v-else>
                <span :aria-current="crumb.active ? 'location' : null">
                  {{ crumb.text($route, $store) }}</span
                >
              </template>
            </li>
          </ol>
        </template>

        <!--RIGHT :  Language  || User Account-->
        <div class="rc-nav-right">
          <ul class="navbar-nav">
            <li class="nav-item rc-lang">
              <RcLanguage />
            </li>
            <!-- Not authentificated (Btn Sign In)-->

            <template v-if="!isAuthenticated">
              <li class="nav-item" v-if="!isLoginPage">
                <router-link
                  :to="toLogin"
                  class="nav-link btn-primary btn rc-btn-sign-in"
                  >{{ $t(`auth.log.in`) }}</router-link
                >
              </li>
            </template>
            <!-- Authentificated -->
            <template v-else>
              <!-- My account -->
              <b-nav-item-dropdown
                right
                no-caret
                toggleClass="rc-btn-icon-dd"
                class="rc-menu-account"
                role="presentation"
                id="dd-account"
              >
                <template #button-content>
                  <div class="rc-btn-dropdown-my-account">
                    <RcIcon
                      name="userCircle"
                      scale="1"
                      :title="$t('nav.my_account')"
                    >
                    </RcIcon>
                  </div>
                </template>

                <template v-if="profile.user_uid && getTeamUID && !roleIsGuest">
                  <b-dropdown-item
                    :to="{
                      name: 'user.favorites',
                      params: { locale: $i18n.locale }
                    }"
                  >
                    <span class="rc-link-icon--text">
                      <RcIcon name="bookmarkSmall" scale="1.125"></RcIcon>
                      {{ $t("catalog.favorites.my") }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-group
                    class="rc-team-switcher"
                    :header="$tc('user.my_teams', memberships.length)"
                    header-classes="rc-team-switcher--header"
                  >
                    <RcJoinedTeams
                      @change="onChange"
                      :options="{
                        manage: 'user.account.team.join',
                        role: false,
                        tagSignedIn: true
                      }"
                    ></RcJoinedTeams>
                  </b-dropdown-group>

                  <b-dropdown-divider />
                </template>

                <b-dropdown-item-button role="button" @click="logoutHandler()">
                  <span class="rc-link-icon--text">
                    {{ $t("auth.log.out") }}
                  </span>
                  <RcIcon name="logOut" scale="1"></RcIcon>
                </b-dropdown-item-button>
              </b-nav-item-dropdown>
            </template>
          </ul>
        </div>

        <!-- Sidebar Nav-->
        <b-sidebar
          id="rcNavSidebar"
          ariaLabel="Navigation"
          bgVariant="dark"
          backdropVariant="black"
          textVariant="white"
          sidebarClass="rc-nav-sidebar"
          backdrop
          noCloseOnRouteChange
          noHeader
          @change="shown"
        >
          <template v-slot:default="{ hide }">
            <!-- header sidebar -->
            <div class="rc-header-sidebar">
              <button class="btn rc-btn-icon" @click="hide()">
                <RcIcon
                  name="closeBold"
                  scale="1.2"
                  :title="$t(`common.close`)"
                ></RcIcon>
              </button>

              <div class="rc-logo-wrapper">
                <img
                  v-if="teamInfo.data.logo"
                  :alt="profile.team_name"
                  class="rc-logo-team"
                  :src="teamInfo.data.logo"
                />
                <div v-else class="mx-auto">
                  {{ profile.team_name }}
                </div>
              </div>
            </div>

            <ul class="navbar-nav rc-navbar-home">
              <li class="nav-item" @click="hide()">
                <router-link :to="toHomeUser" class="nav-link"
                  ><span class="rc-link-icon--text">
                    <RcIcon name="home" scale=".8" />{{ $t("nav.home") }}</span
                  ></router-link
                >
              </li>
            </ul>
            <ul class="navbar-nav">
              <!-- Library -->
              <li class="navbar-text">{{ $t("nav.video_on_demand") }}</li>

              <li class="nav-item" @click="hide()">
                <router-link :to="toCatalog" class="nav-link">
                  <div class="rc-link-icon--text">
                    <RcIcon name="clip" scale="1" />Clips
                  </div>
                </router-link>
              </li>
              <li class="nav-item" v-if="profile.user_uid" @click="hide()">
                <router-link
                  :to="{
                    name: 'user.playlists',
                    params: { locale: $i18n.locale }
                  }"
                  class="nav-link"
                  ><div class="rc-link-icon--text">
                    <RcIcon name="playlistStack" scale="1.1"></RcIcon
                    >{{ $t("nav.playlists") }}
                  </div>
                </router-link>
              </li>
              <li class="nav-item" @click="hide()">
                <router-link :to="toEpisodics" class="nav-link">
                  <div class="rc-link-icon--text">
                    <RcIcon name="episodic" scale="1.2" />
                    {{ $t("catalog.shows_and_series") }}
                  </div>
                </router-link>
              </li>
              <!-- <li class="nav-item" @click="hide()">
                <router-link :to="toLongFormats" class="nav-link">
                  <div class="rc-link-icon--text">
                    <RcIcon
                      name="film"
                      scale="1.2

                    "
                    />
                    {{ $tc("catalog.group_type.long_format.title", 2) }}
                  </div>
                </router-link>
              </li> -->
            </ul>
            <ul class="navbar-nav">
              <li class="navbar-text">{{ $t("nav.live_replays") }}</li>

              <li class="nav-item" @click="hide()">
                <router-link :to="toStreams" class="nav-link"
                  ><span class="rc-link-icon--text"
                    ><RcIcon name="antenna" scale="1.2"></RcIcon
                    >{{ $tc("catalog.stream.name", 2) }}</span
                  ></router-link
                >
              </li>
            </ul>
            <!-- List channels of the team -->
            <ul class="navbar-nav" v-if="fastChannels.data.results.length == 1">
              <li class="navbar-text">
                {{ fastChannels.data.results[0].name }}
              </li>

              <li class="nav-item" @click="hide()">
                <router-link
                  :to="{
                    name: 'fastChannel.monitor',
                    params: {
                      slug: fastChannels.data.results[0].slug,
                      locale: $i18n.locale
                    },
                    query: { day: $route.query.day }
                  }"
                  class="nav-link"
                >
                  <span class="rc-link-icon--text"
                    ><RcIcon name="playMonitor" scale="1.1"></RcIcon
                    >{{ $t("common.monitor") }}</span
                  ></router-link
                >
              </li>

              <li class="nav-item" @click="hide()">
                <router-link
                  :to="{
                    name: 'fastChannel.week',
                    params: {
                      slug: fastChannels.data.results[0].slug,
                      locale: $i18n.locale
                    },
                    query: scheduleQuery
                  }"
                  class="nav-link"
                >
                  <span class="rc-link-icon--text"
                    ><RcIcon name="calendar" scale="1.1"></RcIcon
                    >{{ $t("schedule.title") }}</span
                  ></router-link
                >
              </li>
            </ul>

            <div
              class="accordion"
              role="tablist"
              v-else-if="fastChannels.data.results.length > 1"
            >
              <div
                v-for="channel in fastChannels.data.results"
                :key="channel.slug"
              >
                <rc-btn
                  role="tab"
                  block
                  v-b-toggle="`fastChannelMenu-${channel.slug}`"
                  variant="transparent"
                  class="rc-btn-toggle"
                >
                  <span>{{ channel.name }}</span
                  ><span>
                    <RcIcon name="chevronUpBold" scale=".5"></RcIcon>
                    <RcIcon name="chevronDownBold" scale=".5"></RcIcon>
                  </span>
                </rc-btn>

                <b-collapse
                  :id="`fastChannelMenu-${channel.slug}`"
                  :ref="`fastChannelMenu-${channel.slug}`"
                  visible
                  accordion="rc-nav-accordion"
                  role="tabpanel"
                >
                  <ul class="navbar-nav">
                    <li class="nav-item" @click="hide()">
                      <router-link
                        :to="{
                          name: 'fastChannel.monitor',
                          params: { slug: channel.slug, locale: $i18n.locale },
                          query: { day: $route.query.day }
                        }"
                        class="nav-link"
                      >
                        <span class="rc-link-icon--text"
                          ><RcIcon name="playMonitor" scale="1.1"></RcIcon
                          >{{ $t("common.monitor") }}</span
                        ></router-link
                      >
                    </li>

                    <li class="nav-item" @click="hide()">
                      <router-link
                        :to="{
                          name: 'fastChannel.week',
                          params: { slug: channel.slug, locale: $i18n.locale },
                          query: scheduleQuery
                        }"
                        class="nav-link"
                      >
                        <span class="rc-link-icon--text"
                          ><RcIcon name="calendar" scale="1.1"></RcIcon
                          >{{ $t("schedule.title") }}</span
                        ></router-link
                      >
                    </li>
                  </ul>
                </b-collapse>
              </div>
            </div>

            <!------>
            <ul class="navbar-nav">
              <!-- Import -->

              <li class="nav-item" @click="hide()">
                <router-link :to="toImports" class="nav-link"
                  ><span class="rc-link-icon--text"
                    ><RcIcon name="download" scale="1.1" />Imports</span
                  ></router-link
                >
              </li>
            </ul>
          </template>
        </b-sidebar>
      </nav>
      <!--mobile breadcrumb-->
      <nav v-if="breadcrumbs" :aria-label="$t(`a11y.nav.breadcrumb`)">
        <ol class="breadcrumb d-lg-none ml-0" v-if="breadcrumbs.crumbs">
          <li class="breadcrumb-item">
            <router-link :to="toHomeUser"> {{ $t("nav.home") }} </router-link>
          </li>

          <li
            v-for="(crumb, index) in breadcrumbs.crumbs"
            class="breadcrumb-item"
            :key="index"
            :class="crumb.active ? 'active' : ''"
          >
            <template v-if="crumb.to || crumb.fnTo">
              <router-link
                :to="crumb.fnTo ? crumb.fnTo($route) : crumb.to"
                :class="crumb.disabled ? 'disabled' : ''"
              >
                {{ crumb.text($route, $store) }}</router-link
              ></template
            >
            <template v-else>
              <span :aria-current="crumb.active ? 'location' : null">
                {{ crumb.text($route, $store) }}</span
              >
            </template>
          </li>
        </ol>
      </nav>
    </div>
  </header>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";
import { navMixin } from "#/routes/mixins";
import { AUTH_LOGOUT } from "@/libvue/apps/auth/actions";

import RcLanguage from "@/libvuePure/components/i18n/LocaleSwitch";
import RcIcon from "@/libvuePure/components/icons/Base";
import RcJoinedTeams from "@/libvue/apps/team/components/JoinedTeams";

export default {
  name: "RcSiteHeader",
  mixins: [navMixin],
  components: {
    RcLanguage,
    RcIcon,
    RcJoinedTeams
  },
  data() {
    return {
      lastChannel: null
    };
  },

  computed: {
    ...mapGetters([
      "fastChannels",
      "roleIsGuest",
      "isAuthenticated",
      "getTeamUID"
    ]),
    ...mapState({
      memberships: (state) => state.team.profile.memberships,
      teamInfo: (state) => state.team.info,
      profile: (state) => state.auth.user
    }),

    breadcrumbs() {
      return this.$route.meta.breadcrumb;
    },
    isLoginPage() {
      return (
        this.$route.name == "auth.login" || this.$route.name == "auth.login.pin"
      );
    },
    scheduleQuery() {
      return {
        week: Number(moment().isoWeek()),
        year: Number(moment().year())
      };
    }
  },
  methods: {
    onChange(e) {
      if (e.route !== "home.user") {
        this.$router.push({
          name: "home.user",
          params: { locale: this.$i18n.locale }
        });
      }
    },
    shown() {
      if (this.fastChannels.data.results.length <= 1) {
        return;
      } else if (
        this.$route.name == "fastChannel.monitor" ||
        this.$route.name == "fastChannel.week"
      ) {
        const channelMenu = `fastChannelMenu-${this.$route.params.slug}`;
        this.$refs[channelMenu][0].show = true;
        this.lastChannel = channelMenu;
      } else if (this.lastChannel) {
        this.$refs[this.lastChannel][0].show = true;
      }
    },
    logoutHandler() {
      this.$store.dispatch(AUTH_LOGOUT);
    }
  }
};
</script>
