import app from "#/app";

import {
  getUserInfo,
  getPageInfo,
  getTeamInfo,
  getSearchInfo,
  getFiltersInfo
} from "@/plugins/statistics/baseStats";
import Config from "#/config";
import { eventMap } from "#/plugins/statsEventMap";
import { Murdock } from "@cbcrc/murdock";

const murdockService = new Murdock({
  host: Config.apiHost() ? Config.apiHost() : window.location.hostname,
  basePath: "api/v2/stats",
  protocol: Config.API().use_ssl ? "https" : "http",
  requestType: "beaconAPI"
});

const rcActionStats = (store) => {
  murdockService.initAutoTracking();
  store.subscribe((mutation, state) => {
    if (mutation.payload && eventMap[mutation.type]) {
      app.$nextTick(() => {
        setTimeout(
          () => {
            const payload = formatPayload(mutation, state);
            postActionStats(payload);
          },
          eventMap[mutation.type].postDelay
            ? eventMap[mutation.type].postDelay
            : 0
        );
      });
    }
  });
};
const formatPayload = (m, state) => {
  const info = {};

  info.event_type = eventMap[m.type].event_type;
  if ("user_uid" in state.auth.user && state.auth.user.user_uid)
    info.user_info = getUserInfo(state.auth.user);
  info.page_info = getPageInfo(app.$router.currentRoute);
  info.object_info = getOjectInfo(m);

  if (Config.team.enabled) {
    info.team_info = getTeamInfo(state.auth.user);
  }

  if (m.type == "CATALOG_ITEM_SEARCH_SUCCESS") {
    info.search_info = getSearchInfo(state);
  }

  if (m.type == "ITEM_SET_FILTER_PARAMS" || m.type == "ITEM_SET_USER_FILTER") {
    info.search_info = getFiltersInfo(state);
  }

  return info;
};
const getOjectInfo = (m) => {
  return {
    object_type: eventMap[m.type].object_type,
    object_uid: Object.byString(m.payload, eventMap[m.type].object_uid),
    object_name: Object.byString(m.payload, eventMap[m.type].object_name),
    object_metadata: m.payload.metadata ? m.payload.metadata : {}
  };
};
const postActionStats = (payload) => {
  murdockService.deferEvent(payload.event_type, payload);
};
Object.byString = function (o, s) {
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export default rcActionStats;

/*
search: CATALOG_ITEM_SEARCH_SUCCESS,
item_preview_viewed: CATALOG_ITEM_GET_SUCCESS,
//item_detail_viewed: this is owned by pagestats
item_screener_viewed: CATALOG_ITEM_SCREENER_ISPLAYING
// item_played is redundant
item_added_to_quote,
item_purchased,
item_under_license,
item_added_to_favorite: CATALOG_FAVORITE_ADD_SUCCESS
item_added_to_playlist: [CATALOG_PLAYLIST_CREATE_SUCCESS, CATALOG_PLAYLIST_ADD_SUCCESS]
item_requested_info

// params
page_url
page_title
object_uid
object_type
object_name
search_type
Search types are : simple, advanced, filtered
search_terms
search_filters

*/
