export const ITEM_GET = "ITEM_GET";
export const ITEM_GET_SUCCESS = "ITEM_GET_SUCCESS";
export const ITEM_GET_ERROR = "ITEM_GET_ERROR";

export const ITEM_GET_LIST = "ITEM_GET_LIST";
export const ITEM_GET_LIST_SUCCESS = "ITEM_GET_LIST_SUCCESS";
export const ITEM_GET_LIST_ERROR = "ITEM_GET_LIST_ERROR";

export const ITEM_MGET = "ITEM_MGET";
export const ITEM_MGET_SUCCESS = "ITEM_MGET_SUCCESS";
export const ITEM_MGET_ERROR = "ITEM_MGET_ERROR";

export const ITEM_GET_LIST_BY_FACET = "ITEM_GET_LIST_BY_FACET";
export const ITEM_GET_LIST_BY_FACET_SUCCESS = "ITEM_GET_LIST_BY_FACET_SUCCESS";
export const ITEM_GET_LIST_BY_FACET_ERROR = "ITEM_GET_LIST_BY_FACET_ERROR";

export const ITEM_SET_FILTER_PARAMS = "ITEM_SET_FILTER_PARAMS";

export const ITEM_SET_USER_FILTER = "ITEM_SET_USER_FILTER";

export const GET_AGE_GROUPS = "GET_AGE_GROUPS";
export const GET_AGE_GROUPS_SUCCESS = "GET_AGE_GROUPS_SUCCESS";
export const GET_AGE_GROUPS_ERROR = "GET_AGE_GROUPS_ERROR";

export const GET_TEAM_AGE_GROUPS = "GET_TEAM_AGE_GROUPS";
export const GET_TEAM_AGE_GROUPS_SUCCESS = "GET_TEAM_AGE_GROUPS_SUCCESS";
export const GET_TEAM_AGE_GROUPS_ERROR = "GET_TEAM_AGE_GROUPS_ERROR";

export const GET_ITEMS_AUTOCOMPLETE = "GET_ITEMS_AUTOCOMPLETE";
export const GET_ITEMS_AUTOCOMPLETE_SUCCESS = "GET_ITEMS_AUTOCOMPLETE_SUCCESS";
export const GET_ITEMS_AUTOCOMPLETE_ERROR = "GET_ITEMS_AUTOCOMPLETE_ERROR";

export const SET_AUTOCOMPLETE_QUERY = "SET_AUTOCOMPLETE_QUERY";
export const SET_AUTOCOMPLETE_SELECTED = "SET_AUTOCOMPLETE_SELECTED";

export const ITEMS_APPEND_NEXT = "ITEMS_APPEND_NEXT";
export const ITEMS_APPEND_NEXT_SUCCESS = "ITEMS_APPEND_NEXT_SUCCESS";
export const ITEMS_APPEND_NEXT_ERROR = "ITEMS_APPEND_NEXT_ERROR";

export const ITEMS_APPEND_NEXT_THEMES = "ITEMS_APPEND_NEXT_THEMES";
export const ITEMS_APPEND_NEXT_THEMES_SUCCESS =
  "ITEMS_APPEND_NEXT_THEMES_SUCCESS";
export const ITEMS_APPEND_NEXT_THEMES_ERROR = "ITEMS_APPEND_NEXT_THEMES_ERROR";

export const ITEM_GET_AGGS = "ITEM_GET_AGGS";
export const ITEM_GET_AGGS_SUCCESS = "ITEM_GET_AGGS_SUCCESS";
export const ITEM_GET_AGGS_ERROR = "ITEM_GET_AGGS_ERROR";

export const ITEM_SET_AGGS_FILTER = "ITEM_SET_AGGS_FILTER";

export const GET_CHANNEL = "GET_CHANNEL";
export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS";
export const GET_CHANNEL_ERROR = "GET_CHANNEL_ERROR";

export const GET_CHANNELS = "GET_CHANNELS";
export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS";
export const GET_CHANNELS_ERROR = "GET_CHANNELS_ERROR";

export const GET_CHANNEL_ITEMS = "GET_CHANNEL_ITEMS";
export const GET_CHANNEL_ITEMS_SUCCESS = "GET_CHANNEL_ITEMS_SUCCESS";
export const GET_CHANNEL_ITEMS_ERROR = "GET_CHANNEL_ITEMS_ERROR";

export const GET_THEMES = "GET_THEMES";
export const GET_THEMES_SUCCESS = "GET_THEMES_SUCCESS";
export const GET_THEMES_ERROR = "GET_THEMES_ERROR";

export const GET_THEME = "GET_THEME";
export const GET_THEME_SUCCESS = "GET_THEME_SUCCESS";
export const GET_THEME_ERROR = "GET_THEME_ERROR";

export const GET_THEME_ITEMS_BY_FACET = "GET_THEME_ITEMS_BY_FACET";
export const GET_THEME_ITEMS_BY_FACET_SUCCESS =
  "GET_THEME_ITEMS_BY_FACET_SUCCESS";
export const GET_THEME_ITEMS_BY_FACET_ERROR = "GET_THEME_ITEMS_BY_FACET_ERROR";

export const GET_GROUP = "GET_GROUP";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_ERROR = "GET_GROUP_ERROR";

export const GET_GROUP_APPEND_NEXT = "GET_GROUP_APPEND_NEXT";
export const GET_GROUP_APPEND_NEXT_SUCCESS = "GET_GROUP_APPEND_NEXT_SUCCESS";
export const GET_GROUP_APPEND_NEXT_ERROR = "GET_GROUP_APPEND_NEXT_ERROR";

export const SET_ITEM_SCREENER_ISPLAYING = "SET_ITEM_SCREENER_ISPLAYING";

export const ITEM_GET_FEATURED_LIST = "ITEM_GET_FEATURED_LIST";
export const ITEM_GET_FEATURED_LIST_SUCCESS = "ITEM_GET_FEATURED_LIST_SUCCESS";
export const ITEM_GET_FEATURED_LIST_ERROR = "ITEM_GET_FEATURED_LIST_ERROR";

export const SET_ITEM_DETAIL = "SET_ITEM_DETAIL";

export const SET_ITEM_INITIAL_COUNT = "SET_ITEM_INITIAL_COUNT";

export const SET_UP_NEXT_LIST = "SET_UP_NEXT_LIST";
export const TRACK_UP_NEXT = "TRACK_UP_NEXT";

export const GET_JSON_LD = "GET_JSON_LD";
export const GET_JSON_LD_SUCCESS = "GET_JSON_LD_SUCCESS";
export const GET_JSON_LD_ERROR = "GET_JSON_LD_ERROR";
