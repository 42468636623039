import axios from "@/utils/axios.js";
import * as constants from "./actions";
import Config from "#/config";

const CATEGORIES_AUTH_REQUIRED =
  Config.catalog.categoriesAuthRequired === undefined
    ? false
    : Config.catalog.categoriesAuthRequired;

function getDefaultState() {
  return {
    list: {
      data: { results: [] },
      status: "",
      errors: ""
    },
    themesList: {
      data: { results: [] },
      status: "",
      errors: ""
    },
    detail: {
      data: { results: [], children: [] },
      status: "",
      errors: ""
    },
    parent: {
      data: { results: [], children: [] },
      status: "",
      errors: ""
    }
  };
}

const getters = {
  getCategoryList: (state) => {
    return state.list.data;
  },
  getCategory: (state) => (uid) => {
    return (
      state.list.data.results.find((cat) =>
        cat.uid ? cat.uid == uid : cat == uid
      ) || {}
    );
  },
  getCategoryDetail: (state) => {
    return state.detail.data;
  },
  getSubCategory: (state) => (uid) => {
    return state.detail.data.children.find((cat) => cat.uid == uid);
  }
};

const actions = {
  [constants.GET_CATEGORIES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_CATEGORIES);

      axios({ url: `categories`, authRequired: CATEGORIES_AUTH_REQUIRED })
        .then((response) => {
          commit(constants.GET_CATEGORIES_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_CATEGORIES_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_THEMES_CATEGORIES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_THEMES_CATEGORIES);

      axios({ url: `themes/categories`, authRequired: true })
        .then((response) => {
          commit(constants.GET_THEMES_CATEGORIES_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_THEMES_CATEGORIES_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.GET_CATEGORY]: ({ commit }, uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_CATEGORY);

      axios({
        url: `categories/${uid}`,
        authRequired: CATEGORIES_AUTH_REQUIRED
      })
        .then((response) => {
          commit(constants.GET_CATEGORY_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_CATEGORY_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_PARENT_CATEGORY]: ({ commit }, uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_PARENT_CATEGORY);

      axios({
        url: `categories/${uid}`,
        authRequired: CATEGORIES_AUTH_REQUIRED
      })
        .then((response) => {
          commit(constants.GET_PARENT_CATEGORY_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_PARENT_CATEGORY_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_CATEGORIES]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
    state.list.data = { results: [], children: [] };
  },
  [constants.GET_CATEGORIES_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data = data;
    state.list.data.results = data.results.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  },
  [constants.GET_CATEGORIES_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },
  [constants.GET_THEMES_CATEGORIES]: (state) => {
    state.themesList.status = "fetching";
    state.themesList.errors = {};
    state.themesList.data = { results: [], children: [] };
  },
  [constants.GET_THEMES_CATEGORIES_SUCCESS]: (state, { data }) => {
    state.themesList.status = "success";
    state.themesList.errors = {};
    state.themesList.data = data;
    state.themesList.data.results = data.results.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  },
  [constants.GET_THEMES_CATEGORIES_ERROR]: (state, err) => {
    state.themesList.status = "error";
    state.themesList.errors = err;
  },

  [constants.GET_CATEGORY]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
    state.detail.data = { results: [], children: [] };
  },
  [constants.GET_CATEGORY_SUCCESS]: (state, { data }) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
    state.detail.data.children = data.children.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  },
  [constants.GET_CATEGORY_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = err;
  },
  [constants.GET_PARENT_CATEGORY]: (state) => {
    state.parent.status = "fetching";
    state.parent.errors = {};
    state.parent.data.children = [];
  },
  [constants.GET_PARENT_CATEGORY_SUCCESS]: (state, { data }) => {
    state.parent.status = "success";
    state.parent.errors = {};
    state.parent.data = data;
  },
  [constants.GET_PARENT_CATEGORY_ERROR]: (state, err) => {
    state.parent.status = "error";
    state.parent.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
