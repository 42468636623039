import { ifAuthenticated, ifUserInTeam } from "#/stores/auth";
import i18n from "#/locales";
import store from "#/stores";

const routes = [
  {
    path: "clips",
    name: "catalog.list",
    component: () => import(`#/pages/catalog/BasicList.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.catalog.title",
      breadcrumb: {
        crumbs: [
          {
            text: () => "Clips",
            active: true
          }
        ]
      }
    }
  },
  
  //Clip detail
  {
    path: "clips/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.item.detail",
    component: () => import(`#/pages/catalog/ItemDetail.vue`),
    beforeEnter: ifUserInTeam,
    props: (route) => ({ in: route.query.in, out: route.query.out }),

    meta: {
      breadcrumb: {
        crumbs: [
          {
            text: () => "Clips",
            to: { name: "catalog.list" }
          },
          {
            text: () => i18n.t("catalog.clip_detail"),
            active: true
          }
        ]
      },

      redirectToLang: "home.user"
    }
  },
  //Show - Clips
  {
    path: "show/:show",
    alias: "emission/:show",
    name: "catalog.show.detail",
    component: () => import(`#/pages/catalog/ShowList.vue`),
    props: (route) => ({ query: route.query.q }),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.catalog.title",
      alias: [null, null, "emission", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => "Clips",
            to: { name: "catalog.list" }
          },
          {
            text: () => i18n.t("catalog.show"),
            active: true
          }
          // {
          //   text: (route) => route.params.show,
          //   active: true
          // }
        ]
      }
    }
  },

  //Episodic list
  {
    path: "shows-and-series",
    name: "catalog.episodic.list",
    alias: "emissions-et-series",
    component: () => import(`#/pages/catalog/EpisodicList.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.shows_and_series.title",
      alias: [null, null, "emissions-et-series"],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.t("catalog.shows_and_series"),
            active: true
          }
        ]
      }
    }
  },
   //Episodic detail
  {
    path: "shows-and-series/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.episodic.detail",
    alias:
      "emissions-et-series/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: () => import(`#/pages/catalog/EpisodicDetail.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      alias: [null, null, "emissions-et-series", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.t("catalog.shows_and_series"),
            to: { name: "catalog.episodic.list" }
          },
          {
            text: () => i18n.t("catalog.season_detail"),
            active: true
          }
        ]
      }
    }
  },
  //Long format list
  {
    path: "long-formats",
    name: "catalog.longFormats.list",
    alias: "formats-longs",
    component: () => import(`#/pages/catalog/LongFormatList.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.long_formats.title",
      alias: [null, null, "formats-longs"],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.tc("catalog.group_type.long_format.title", 2),
            active: true
          }
        ]
      }
    }
  },
  //Long format detail
  {
    path: "long-formats/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.longFormats.detail",
    alias:
      "formats-longs/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: () => import(`#/pages/catalog/LongFormatDetail.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      alias: [null, null, "formats-longs", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.tc("catalog.group_type.long_format.title", 2),
            to: { name: "catalog.longFormats.list" }
          },
          {
            text: () => i18n.t("catalog.group_type.long_format.detail"),
            active: true
          }
        ]
      }
    }
  },

  //Category - Clips
  {
    path: "clips/category/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.category.detail",
    alias:
      "clips/categorie/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: () => import(`#/pages/catalog/CategoryList.vue`),
    props: (route) => ({ query: route.query.q }),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.catalog.title",
      alias: [null, null, null, "categorie", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => "Clips",
            to: () => ({
              name: "catalog.list"
            })
          },

          {
            text: () => i18n.tc("catalog.categories", 1),
            active: true
          }
        ]
      }
    }
  },
  //Category - Episodic
  {
    path: "shows-and-series/category/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.episodicCategory.detail",
    alias:
      "emissions-et-series/categorie/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: () => import(`#/pages/catalog/CategoryEpisodicList.vue`),
    props: (route) => ({ query: route.query.q }),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.catalog.title",
      alias: [null, null, "emissions-et-series", "categorie", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.t("catalog.shows_and_series"),
            to: {
              name: "catalog.episodic.list"
            }
          },

          {
            text: () => i18n.tc("catalog.categories", 1),
            active: true
          }
        ]
      }
    }
  },
  //Category - Long formats
  {
    path: "long-formats/category/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.longCategory.detail",
    alias:
      "formats-longs/categorie/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: () => import(`#/pages/catalog/CategoryLongList.vue`),
    props: (route) => ({ query: route.query.q }),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.catalog.title",
      alias: [null, null, "formats-longs", "categorie", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.tc("catalog.group_type.long_format.title", 2),
            to: {
              name: "catalog.longFormats.list"
            }
          },

          {
            text: () => i18n.tc("catalog.categories", 1),
            active: true
          }
        ]
      }
    }
  },

  //Keyword - Clips
  {
    path: "clips/tag/:keyword",
    alias: "clips/libelle/:keyword",
    name: "catalog.keyword.detail",
    component: () => import(`#/pages/catalog/KeywordList.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.catalog.title",
      alias: [null, null, null, "libelle", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => "Clips",
            to: {
              name: "catalog.list"
            }
          },
          {
            text: () => i18n.t("common.keyword"),
            active: true
          }
        ]
      }
    }
  },
  //Keyword - Episodic
  {
    path: "shows-and-series/tag/:keyword",
    alias: "emissions-et-series/libelle/:keyword",
    name: "catalog.episodicKeyword.detail",
    component: () => import(`#/pages/catalog/KeywordEpisodicList.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.catalog.title",
      alias: [null, null, "emissions-et-series", "libelle", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.t("catalog.shows_and_series"),
            to: {
              name: "catalog.episodic.list"
            }
          },
          {
            text: () => i18n.t("common.keyword"),
            active: true
          }
        ]
      }
    }
  },
  //Keyword - Long formats
  {
    path: "long-formats/tag/:keyword",
    alias: "formats-longs/libelle/:keyword",
    name: "catalog.longKeyword.detail",
    component: () => import(`#/pages/catalog/KeywordLongList.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.catalog.title",
      alias: [null, null, "formats-longs", "libelle", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.tc("catalog.group_type.long_format.title", 2),
            to: {
              name: "catalog.longFormats.list"
            }
          },
          {
            text: () => i18n.t("common.keyword"),
            active: true
          }
        ]
      }
    }
  },


  //Group type list - NOT USED
  {
    path: "group/:type",
    name: "catalog.group.type",
    beforeEnter: ifAuthenticated,
    component: () => import(`#/pages/catalog/GroupTypeList.vue`),
    meta: {
      breadcrumb: {
        back: false,
        crumbs: [
          {
            text: () => "Groupe type",
            active: true
          }
        ]
      }
    }
  },
   //Group type slug - Episodic
  {
    path: "shows-and-series/group/:type/:slug",
    alias: "emissions-et-series/groupe/:type/:slug",
    name:"catalog.episodicGroupSlug.detail",
    beforeEnter: ifAuthenticated,

    component: () => import(`#/pages/catalog/GroupSlugEpisodicList.vue`),
     meta: {
      title: "pageMeta.catalog.title",
      alias: [null, null, "emissions-et-series", "groupe", null, null],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.t("catalog.shows_and_series"),
            to: {
              name: "catalog.episodic.list"
            }
          },
          {
            text: (route) =>
              i18n.tc("catalog.group_type." + route.params.type + ".title", 1),
             
            active: true
          }
        ]
      }
    }
   
  },


  //Streams list
  {
    path: "streams",
    name: "catalog.streams",
    alias: "flux",
    component: () => import(`#/pages/catalog/StreamList.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.catalog.title",
      alias: [null, null, "flux"],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.tc("catalog.stream.name", 2),
            active: true
          }
        ]
      }
    }
  },
  //Streams detail
  {
    path: "streams/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.streams.detail",
    alias:
      "flux/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: () => import(`#/pages/catalog/StreamDetail.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.catalog.title",
      alias: [null, null, "flux", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.tc("catalog.stream.name", 2),
            to: { name: "catalog.streams" }
          },
          {
            text: () => i18n.t("catalog.stream.detail"),
            active: true
          }
        ]
      }
    }
  },

  
  
  //Imports list
  {
    path: "imports",
    name: "catalog.import.list",
    component: () => import(`#/pages/catalog/ImportLogList.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.import.title",
      breadcrumb: {
        crumbs: [
          {
            text: () => "Imports",
            active: true
          }
        ]
      }
    }
  },


 
  //Import search - NOT USED
  {
    path: "import-search",
    name: "catalog.import.search",
    component: () => import(`#/pages/catalog/ImportSearch.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.import.title",
      breadcrumb: {
        crumbs: [
          {
            text: () => "Clips",
            to: { name: "catalog.list" }
          },
          {
            text: () => "Import",
            active: true
          }
        ]
      }
    }
  },
  //Import - NOT USED
  {
    path: "import-results/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "catalog.import.results",
    component: () => import(`#/pages/catalog/ImportList.vue`),
    beforeEnter: ifUserInTeam,
    meta: {
      title: "pageMeta.import.title",
      breadcrumb: {
        crumbs: [
          {
            text: () => "Clips",
            to: { name: "catalog.list" }
          },
          {
            text: () => "Import",
            to: { name: "catalog.import.search" }
          },
          {
            text: () => i18n.t("common.results"),
            active: true
          }
        ]
      }
    }
  },
  

  //Search -  NOT USED
  {
    path: "search",
    name: "catalog.item.search",
    beforeEnter: ifUserInTeam,
    component: () => import(`#/pages/catalog/SearchList.vue`),
    props: (route) => ({ query: route.query.q }),
    meta: {
      title: "pageMeta.catalog.title",
      breadcrumb: {
        crumbs: [
          {
            text: () => "Clips",
            to: { name: "catalog.list" }
          },
          {
            text: () => i18n.t("common.search.title"),
            active: true
          }
        ]
      }
    }
  },


];

export default routes;
