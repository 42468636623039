<template>
  <div>
    <b-dropdown-item
      v-for="m in listMyTeams"
      :key="m.team.uid"
      :active="m.team.uid === profile.team_uid"
      :text="options.text"
      @click="onTeamClick(m.team)"
    >
      <div class="rc-team-name">{{ m.team.name }}</div>
      <div
        v-if="options.tagSignedIn && m.team.uid === profile.team_uid"
        class="rc-tag-signedIn"
      >
        {{ $t("common.signed_in") }}
      </div>
      <div v-if="options.role" class="text-muted">
        <div v-if="m.role.includes('admin')">
          {{ $t("team.admin") }}
        </div>

        <div v-if="m.role.includes('student')">
          {{ $t("common.student") }}
        </div>

        <div v-else-if="m.role.includes('teacher')">
          {{ $t("common.teacher") }}
        </div>

        <div v-else-if="m.role.includes('library_user')">
          {{ $t("common.librarian") }}
        </div>

        <div v-else-if="m.role.includes('guest')">
          {{ $t("common.guest") }}
        </div>
      </div>
    </b-dropdown-item>

    <template v-if="options.manage || teamsUserIsJoined.length > 5">
      <b-dropdown-item
        link-class="rc-link-all-teams"
        :to="{ name: options.manage, params: { locale: $i18n.locale } }"
        >{{ $t("team.all") }}</b-dropdown-item
      >
    </template>
  </div>
</template>

<script>
import { teamMixin } from "@/libvue/apps/team/mixins";
import store from "#/stores";

export default {
  name: "RcJoinedTeams",
  mixins: [teamMixin],
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          text: "Right align",
          manage: "user.account.team.join",
          role: true,
          tagSignedIn: false
        };
      }
    }
  },
  computed: {
    listMyTeams() {
      return this.teamsUserIsJoined;
    }
  },
  mounted() {
    if (this.profile.user_uid) {
      Promise.all([store.dispatch("USER_TEAMS_REQUEST")]);
    }
  }
};
</script>

<style lang="scss" scoped></style>
