import axios from "@/utils/axios.js";

import * as constants from "./actions";

function getDefaultState() {
  return {
    aggs: {},
    progress: {
      active: false,
      seen: true,
      variant: ""
    },
    detail: { view: "rc-upload-summary", displayName: "Asset detail" },
    status: "",
    errors: "",
    uploader: null,
    evaporate: null,
    sentAssets: {
      data: {},
      status: "",
      errors: ""
    },
    s3Config: {
      data: null,
      errors: null,
      status: ""
    },
    s3Assets: {
      data: [],
      errors: null,
      status: "",
      pagination: {},
      emptyFeedback: "There is no uploads corresponding to this query"
    },
    s3Asset: {
      data: {},
      errors: null,
      status: ""
    },
    s3Signature: {
      data: {},
      errors: null,
      status: ""
    }
  };
}

const actions = {
  [constants.UPLOADS_SEND_TO_REGULATOR]: ({ commit, rootState }, payload) => {
    const team_uid = rootState.auth.user.team_uid;
    const regulator_uid = payload.uid;
    const body = payload.body;

    return new Promise((resolve, reject) => {
      commit(constants.UPLOADS_SEND_TO_REGULATOR);
      axios
        .post(
          `teams/${team_uid}/regulators/${regulator_uid}/get-remote-assets`,
          body
        )
        .then((response) => {
          commit(constants.UPLOADS_SEND_TO_REGULATOR_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.UPLOADS_SEND_TO_REGULATOR_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.UPLOADS_APPEND_NEXT]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.UPLOADS_APPEND_NEXT);

      const url = params.next ? params.next : "";

      axios
        .get(url)
        .then((response) => {
          commit(constants.UPLOADS_APPEND_NEXT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.UPLOADS_APPEND_NEXT_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.UPLOADS_GET_S3_CONFIG]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(constants.UPLOADS_GET_S3_CONFIG);
      const user_uid = rootState.auth.user.user_uid;
      axios
        .get(`users/${user_uid}/uploads`)
        .then((response) => {
          commit(constants.UPLOADS_GET_S3_CONFIG_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.UPLOADS_GET_S3_CONFIG_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.UPLOADS_GET_ASSETS]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(constants.UPLOADS_GET_ASSETS);
      const user_uid = rootState.auth.user.user_uid;
      axios
        .get(`users/${user_uid}/uploads/get-assets`)
        .then((response) => {
          commit(constants.UPLOADS_GET_ASSETS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.UPLOADS_GET_ASSETS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.UPLOADS_GET_S3_SIGNATURE]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.UPLOADS_GET_S3_SIGNATURE);
      const user_uid = rootState.auth.user.user_uid;
      const to_sign = `?to_sign=${params.stringToSign}`;
      const signatureDateTime = params.signatureDateTime;

      axios
        .get(
          `users/${user_uid}/uploads/get-signature${to_sign}&${signatureDateTime}`
        )
        .then((response) => {
          commit(constants.UPLOADS_GET_S3_SIGNATURE_SUCCESS, response);
          resolve(response.data.signature);
        })
        .catch((error) => {
          commit(constants.UPLOADS_GET_S3_SIGNATURE_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.UPLOADS_ASSET_SET_SELECTED]: (state, asset) => {
    state.s3Asset.status = `success`;
    state.s3Asset.errors = {};
    state.s3Asset.data = asset;
  },
  [constants.UPLOADS_ASSET_SET_VIEW_COMPONENT]: (state, detail) => {
    state.status = `success`;
    state.errors = {};
    state.detail = detail;
  },
  [constants.UPLOADS_SEND_TO_REGULATOR]: (state) => {
    state.sentAssets.status = "fetching";
    state.sentAssets.errors = {};
  },
  [constants.UPLOADS_SEND_TO_REGULATOR_SUCCESS]: (state, { data }) => {
    state.sentAssets.status = "success";
    state.sentAssets.errors = {};
    state.sentAssets.data = data.results;
  },
  [constants.UPLOADS_SEND_TO_REGULATOR_ERROR]: (state, err) => {
    state.sentAssets.status = "error";
    state.sentAssets.errors = err;
  },
  [constants.UPLOADS_APPEND_NEXT]: (state) => {
    state.s3Assets.status = `fetching`;
    state.errors = {};
  },
  [constants.UPLOADS_APPEND_NEXT_SUCCESS]: (state, { data }) => {
    state.aggs = data.aggregations;
    state.assets.status = `success`;
    state.assets.errors = {};
    state.assets.data = [...state.assets.data, ...data.results];
    state.assets.pagination = {
      count: data.count,
      next: data.next
    };
  },
  [constants.UPLOADS_APPEND_NEXT_ERROR]: (state, err) => {
    state.assets.status = `error`;
    state.assets.errors = err.response ? err.response.data : err;
  },
  [constants.UPLOADS_GET_S3_CONFIG]: (state) => {
    state.s3Config.status = "fetching";
    state.s3Config.errors = {};
  },
  [constants.UPLOADS_GET_S3_CONFIG_SUCCESS]: (state, { data }) => {
    state.s3Config.status = "success";
    state.s3Config.errors = {};
    state.s3Config.data = data;
  },
  [constants.UPLOADS_GET_S3_CONFIG_ERROR]: (state, err) => {
    state.s3Config.status = "error";
    state.s3Config.errors = err;
  },
  [constants.UPLOADS_GET_ASSETS]: (state) => {
    state.s3Assets.status = "fetching";
    state.s3Assets.errors = {};
    state.s3Assets.data = [];
  },
  [constants.UPLOADS_GET_ASSETS_SUCCESS]: (state, { data }) => {
    state.s3Assets.status = "success";
    state.s3Assets.errors = {};
    state.s3Assets.data = data;
  },
  [constants.UPLOADS_GET_ASSETS_ERROR]: (state, err) => {
    state.s3Assets.status = "error";
    state.s3Assets.errors = err;
  },
  [constants.UPLOADS_GET_S3_SIGNATURE]: (state) => {
    state.s3Signature.status = "fetching";
    state.s3Signature.errors = {};
  },
  [constants.UPLOADS_GET_S3_SIGNATURE_SUCCESS]: (state, { data }) => {
    state.s3Signature.status = "success";
    state.s3Signature.errors = {};
    state.s3Signature.data = data;
  },
  [constants.UPLOADS_GET_S3_SIGNATURE_ERROR]: (state, err) => {
    state.s3Signature.status = "error";
    state.s3Signature.errors = err;
  },
  [constants.UPLOADS_SET_PROGRESS]: (state, progress) => {
    if (progress.reset) {
      state.progress = {
        active: false,
        seen: true,
        variant: ""
      };
    }
    if (progress.fields) {
      state.progress = {
        ...state.progress,
        ...progress.fields
      };
    }
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
