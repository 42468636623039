import Vue from "vue";
import Vuex from "vuex";

// root store
import root from "#/stores/root";

// libvue store, dynamic state for shared accross multiple components
import libvue from "@/libvue/components/store";

// apps store
import auth from "@/libvue/apps/auth/store";
import user from "@/libvue/apps/user/store";
import items from "@/libvue/apps/catalog/items/store";
import screeners from "@/libvue/apps/catalog/screeners/store";
import favorites from "@/libvue/apps/catalog/favorites/store";
import playlists from "@/libvue/apps/catalog/playlists/store";
import categories from "@/libvue/apps/catalog/categories/store";
import ratings from "@/libvue/components/rating/store";
import upload from "@/libvue/apps/upload/store";

import fastChannels from "@/libvue/apps/fastChannels/store";
import team from "@/libvue/apps/team/store";

import importers from "@/libvue/apps/catalog/importers/store";
import rcActionStats from "@/plugins/statistics/actionStats";
import rcBroadcastChannelAPI from "#/stores/plugins/broadcastChannelAPI.js";

const bc = rcBroadcastChannelAPI();

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
export default new Vuex.Store({
  modules: {
    root,
    libvue,
    // apps
    auth,
    items,
    screeners,
    user,
    team,
    favorites,
    playlists,
    fastChannels,
    categories,
    importers,
    ratings,
    upload
  },
  strict: debug,
  plugins: debug ? [bc] : [rcActionStats, bc]
});
