import axios from "@/utils/axios.js";
import { groupBy } from "lodash";
import Vue from "vue";

import moment from "moment";

import * as constants from "./actions";

function getDefaultState() {
  return {
    list: {
      data: { results: [] },
      status: null,
      errors: null
    },
    detail: {
      data: {},
      status: null,
      errors: null
    },
    rules: {
      data: { results: [] },
      status: null,
      errors: null
    },
    occurrences: {
      playing: {
        data: {},
        status: null,
        errors: null
      },
      byDay: { status: null, errors: null },
      byWeek: {
        data: { results: [], occurrencesByDate: {} },
        status: null,
        errors: null
      },
      byEvent: {
        data: { results: [] },
        status: null,
        errors: null
      },
      detail: {},
      post: {
        data: { results: [] },
        status: null,
        errors: null
      },
      patch: {
        data: { results: [] },
        status: null,
        errors: null
      },
      delete: {
        data: { results: [] },
        status: null,
        errors: null
      }
    },
    streams: {
      list: {
        data: { results: [] },
        status: null,
        errors: null
      },
      live: {
        data: { results: [] },
        status: null,
        errors: null
      },
      replay: {
        data: { results: [] },
        status: null,
        errors: null
      },
      detail: {
        data: {},
        status: null,
        errors: null
      },
      deleted: {
        data: {},
        status: null,
        errors: null
      },
      edited: {
        data: {},
        status: null,
        errors: null
      },
      created: {
        data: {},
        status: null,
        errors: null
      }
    },
    event: {
      deleted: {
        data: {},
        status: "",
        error: ""
      },
      patched: {
        data: {},
        status: "",
        error: ""
      },
      posted: {
        data: {},
        status: "",
        error: ""
      },
      builded: {
        data: {},
        status: "",
        error: ""
      },
      detail: {
        data: {},
        status: "",
        error: ""
      }
    },
    sync: {
      status: null,
      errors: null
    }
  };
}

const getters = {
  fastChannels: (state) => state.list,
  fastChannelDetail: (state) => state.detail,
  occurrencesByDay: (state) => (day) => state.occurrences.byDay[day],

  getFastChannel: (state) => (slug) => {
    return state.list.data.results.find((ch) => ch.slug == slug);
  },
  getLiveStream: (state) => (value) => {
    return state.streams.list.data.results.find((obj) => obj.uid == value);
  },
  getRule: (state) => (value) => {
    return state.rules.data.results.find((obj) => obj.id == value);
  }
};

const actions = {
  [constants.GET_FAST_CHANNELS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_FAST_CHANNELS);
      axios({
        url: "fast-channels"
      })
        .then((response) => {
          commit(constants.GET_FAST_CHANNELS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_FAST_CHANNELS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_FAST_CHANNEL]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_FAST_CHANNEL);
      axios({
        url: `fast-channels/${params.slug}`
      })
        .then((response) => {
          commit(constants.GET_FAST_CHANNEL_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_FAST_CHANNEL_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_FAST_CHANNEL_WEEK]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_FAST_CHANNEL_WEEK);
      axios({
        url: `fast-channels/${params.slug}/year/${params.year}/week/${params.week}`
      })
        .then((response) => {
          commit(constants.GET_FAST_CHANNEL_WEEK_SUCCESS, {
            ...response,
            params
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_FAST_CHANNEL_WEEK_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_FAST_CHANNEL_DAY]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_FAST_CHANNEL_DAY, params.day);
      axios({
        url: `fast-channels/${params.slug}/day/${params.day}`
      })
        .then((response) => {
          commit(constants.GET_FAST_CHANNEL_DAY_SUCCESS, {
            ...response,
            day: params.day
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_FAST_CHANNEL_DAY_ERROR, {
            ...error,
            day: params.day
          });
          reject(error);
        });
    });
  },
  [constants.GET_FAST_CHANNEL_RANGE]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_FAST_CHANNEL_RANGE);
      axios({
        url: `fast-channels/${params.slug}/start/${params.start}/end/${params.end}`,
        params: { event_uid: params.uid }
      })
        .then((response) => {
          commit(constants.GET_FAST_CHANNEL_RANGE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_FAST_CHANNEL_RANGE_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.GET_STREAM]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_STREAM);
      axios
        .get(`fast-channels/streams/${params.uid}`)
        .then((response) => {
          commit(constants.GET_STREAM_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_STREAM_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.PATCH_STREAM]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.PATCH_STREAM);
      axios
        .patch(`fast-channels/streams/${params.uid}/edit`, params.payload)
        .then((response) => {
          commit(constants.PATCH_STREAM_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.PATCH_STREAM_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.POST_STREAM]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(constants.POST_STREAM);
      axios
        .post(`fast-channels/streams/create`, payload)
        .then((response) => {
          commit(constants.POST_STREAM_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.POST_STREAM_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_STREAMS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_STREAMS);
      axios
        .get(`fast-channels/streams`)
        .then((response) => {
          commit(constants.GET_STREAMS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_STREAMS_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.PATCH_EVENT]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.PATCH_EVENT);
      axios
        .patch(
          `fast-channels/${params.slug}/events/${params.uid}/edit`,
          params.payload
        )
        .then((response) => {
          commit(constants.PATCH_EVENT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.PATCH_EVENT_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_EVENT]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_EVENT);
      axios
        .get(`fast-channels/${params.slug}/events/${params.uid}`)
        .then((response) => {
          commit(constants.GET_EVENT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_EVENT_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.POST_EVENT]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.POST_EVENT);
      axios
        .post(`fast-channels/${params.slug}/events/create`, params.payload)
        .then((response) => {
          commit(constants.POST_EVENT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.POST_EVENT_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.POST_OCCURRENCE]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.POST_OCCURRENCE);
      axios
        .post(
          `fast-channels/${params.slug}/events/${params.event_uid}/occurrences/create`,
          params.payload
        )
        .then((response) => {
          commit(constants.POST_OCCURRENCE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.POST_OCCURRENCE_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.PATCH_OCCURRENCE]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.PATCH_OCCURRENCE);
      axios
        .patch(
          `fast-channels/${params.slug}/events/${params.event_uid}/occurrences/${params.uid}/edit`,
          params.payload
        )
        .then((response) => {
          commit(constants.PATCH_OCCURRENCE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.PATCH_OCCURRENCE_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.DELETE_OCCURRENCE]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.DELETE_OCCURRENCE);

      axios
        .patch(
          `fast-channels/${params.slug}/events/${params.event_uid}/occurrences/${params.uid}/cancel`
        )
        .then((response) => {
          commit(constants.DELETE_OCCURRENCE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.DELETE_OCCURRENCE_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.POST_EVENT_BUILD]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.POST_EVENT_BUILD);
      axios
        .post(
          `fast-channels/${params.slug}/events/${params.uid}/build`,
          params.payload
        )
        .then((response) => {
          commit(constants.POST_EVENT_BUILD_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.POST_EVENT_BUILD_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.DELETE_EVENT]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.DELETE_EVENT, params);
      axios
        .delete(`fast-channels/${params.slug}/events/${params.uid}/delete`)
        .then((response) => {
          commit(constants.DELETE_EVENT_SUCCESS, {
            ...response.data,
            ...params
          });

          resolve(response);
        })
        .catch((error) => {
          commit(constants.DELETE_EVENT_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.DELETE_EVENT_OCCS]: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.DELETE_EVENT_OCCS);
      axios
        .delete(
          `fast-channels/${params.slug}/events/${params.uid}/occurrences/delete-all`
        )
        .then((response) => {
          commit(constants.DELETE_EVENT_OCCS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.DELETE_EVENT_OCCS_ERROR, error);
          reject(error);
        });
    });
  },

  [constants.GET_FREQUENCY_RULES]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_FREQUENCY_RULES);
      axios
        .get(`fast-channels/rules`)
        .then((response) => {
          commit(constants.GET_FREQUENCY_RULES_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_FREQUENCY_RULES_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_FAST_CHANNELS]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
  },
  [constants.GET_FAST_CHANNELS_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data = data;
  },
  [constants.GET_FAST_CHANNELS_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },
  [constants.GET_FAST_CHANNEL]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
  },
  [constants.GET_FAST_CHANNEL_SUCCESS]: (state, { data }) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },
  [constants.GET_FAST_CHANNEL_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = err;
  },
  [constants.GET_FAST_CHANNEL_WEEK]: (state) => {
    state.occurrences.byWeek.status = "fetching";
    state.occurrences.byWeek.errors = {};
  },
  [constants.GET_FAST_CHANNEL_WEEK_SUCCESS]: (state, { data, params }) => {
    state.occurrences.byWeek.status = "success";
    state.occurrences.byWeek.errors = {};
    state.occurrences.byWeek.data = data;

    // an overnight event is an event who start on a day and finish on the next
    // this event is having a sleepover loll
    let overnight = [];

    state.occurrences.detail = params.occToSet ? params.occToSet : {};

    data.results.forEach((res) => {
      let endMinusOne = moment(res.end).subtract(1, "seconds").format();
      let isSameDay = moment(res.start).isSame(endMinusOne, "day");
      if (!isSameDay) {
        overnight.push({
          ...res,
          overnight: true,
          start: moment(res.end).startOf("day")
        });
      }
    });

    const monthName = (item) => moment(item.start).format("YYYY-MM-DD");
    const occurrences = groupBy(data.results, monthName);

    state.occurrences.byWeek.data.occurrencesByDate = occurrences;

    overnight.forEach((o) => {
      state.occurrences.byWeek.data.occurrencesByDate[
        moment(o.end).format("YYYY-MM-DD")
      ]
        ? state.occurrences.byWeek.data.occurrencesByDate[
            moment(o.end).format("YYYY-MM-DD")
          ].push(o)
        : (state.occurrences.byWeek.data.occurrencesByDate[
            moment(o.end).format("YYYY-MM-DD")
          ] = [o]);
    });
  },
  [constants.GET_FAST_CHANNEL_WEEK_ERROR]: (state, err) => {
    state.occurrences.byWeek.status = "error";
    state.occurrences.byWeek.errors = err;
  },
  [constants.GET_FAST_CHANNEL_DAY]: (state, day) => {
    state.occurrences.byDay.status = "fetching";
    state.occurrences.byDay.errors = {};
    Vue.set(state.occurrences.byDay, day, {
      data: {
        results: []
      },
      status: "fetching",
      errors: {}
    });
  },
  [constants.GET_FAST_CHANNEL_DAY_SUCCESS]: (state, response) => {
    state.occurrences.byDay.status = "success";
    state.occurrences.byDay.errors = {};
    state.occurrences.byDay[response.day].status = "success";
    state.occurrences.byDay[response.day].errors = {};
    state.occurrences.byDay[response.day].data = response.data;

    // an overnight event is an event who start on a day and finish on the next

    response.data.results.forEach((res) => {
      let endMinusOne = moment(res.end).subtract(1, "seconds").format();
      let isSameDay = moment(res.start).isSame(endMinusOne, "day");
      if (!isSameDay && moment(res.end).isSame(response.day, "day")) {
        res.start = moment(response.day).startOf("day").format();
        res.overnight = true;
      }
    });
  },
  [constants.GET_FAST_CHANNEL_DAY_ERROR]: (state, err) => {
    state.occurrences.byDay.status = "error";
    state.occurrences.byDay.errors = err;
    state.occurrences.byDay[err.day].status = "error";
    state.occurrences.byDay[err.day].errors = err;
  },
  [constants.GET_FAST_CHANNEL_RANGE]: (state) => {
    state.occurrences.byEvent.status = "fetching";
    state.occurrences.byEvent.errors = {};
  },
  [constants.GET_FAST_CHANNEL_RANGE_SUCCESS]: (state, { data }) => {
    state.occurrences.byEvent.status = "success";
    state.occurrences.byEvent.errors = {};
    state.occurrences.byEvent.data = data;
  },
  [constants.GET_FAST_CHANNEL_RANGE_ERROR]: (state, err) => {
    state.occurrences.byEvent.status = "error";
    state.occurrences.byEvent.errors = err;
  },

  [constants.SET_OCCURRENCE_DETAIL]: (state, data) => {
    state.occurrences.detail = data;
  },
  [constants.GET_STREAM]: (state) => {
    state.streams.detail.status = "fetching";
    state.streams.detail.errors = {};
    state.streams.detail.data = {};
  },
  [constants.GET_STREAM_SUCCESS]: (state, { data }) => {
    state.streams.detail.status = "success";
    state.streams.detail.errors = {};
    state.streams.detail.data = data;
  },
  [constants.GET_STREAM_ERROR]: (state, err) => {
    state.streams.detail.status = "error";
    state.streams.detail.errors = err;
  },
  [constants.PATCH_STREAM]: (state) => {
    state.streams.edited.status = "fetching";
    state.streams.edited.errors = {};
  },
  [constants.PATCH_STREAM_SUCCESS]: (state, { data }) => {
    state.streams.edited.status = "success";
    state.streams.edited.errors = {};
    state.streams.edited.data = data;
  },
  [constants.PATCH_STREAM_ERROR]: (state, err) => {
    state.streams.edited.status = "error";
    state.streams.edited.errors = err;
  },
  [constants.POST_STREAM]: (state) => {
    state.streams.created.status = "fetching";
    state.streams.created.errors = {};
  },
  [constants.POST_STREAM_SUCCESS]: (state, { data }) => {
    state.streams.created.status = "success";
    state.streams.created.errors = {};
    state.streams.created.data = data;
  },
  [constants.POST_STREAM_ERROR]: (state, err) => {
    state.streams.created.status = "error";
    state.streams.created.errors = err;
  },
  [constants.GET_STREAMS]: (state) => {
    state.streams.list.status = "fetching";
    state.streams.list.errors = {};
  },
  [constants.GET_STREAMS_SUCCESS]: (state, { data }) => {
    state.streams.list.status = "success";
    state.streams.list.errors = {};
    state.streams.list.data = data;
  },
  [constants.GET_STREAMS_ERROR]: (state, err) => {
    state.streams.list.status = "error";
    state.streams.list.errors = err;
  },
  [constants.GET_EVENT]: (state) => {
    state.event.detail.status = "fetching";
    state.event.detail.errors = {};
  },
  [constants.GET_EVENT_SUCCESS]: (state, { data }) => {
    state.event.detail.status = "success";
    state.event.detail.errors = {};
    state.event.detail.data = data;
  },
  [constants.GET_EVENT_ERROR]: (state, err) => {
    state.event.detail.status = "error";
    state.event.detail.errors = err;
  },
  [constants.POST_EVENT]: (state) => {
    state.event.posted.status = "fetching";
    state.event.posted.errors = {};
  },
  [constants.POST_EVENT_SUCCESS]: (state, { data }) => {
    state.event.posted.status = "success";
    state.event.posted.errors = {};
    state.event.posted.data = data;
  },
  [constants.POST_EVENT_ERROR]: (state, err) => {
    state.event.posted.status = "error";
    state.event.posted.errors = err;
  },
  [constants.POST_EVENT_BUILD]: (state) => {
    state.event.builded.status = "fetching";
    state.event.builded.errors = {};
  },
  [constants.POST_EVENT_BUILD_SUCCESS]: (state, { data }) => {
    state.event.builded.status = "success";
    state.event.builded.errors = {};
    state.event.builded.data = data;
  },
  [constants.POST_EVENT_BUILD_ERROR]: (state, err) => {
    state.event.builded.status = "error";
    state.event.builded.errors = err;
  },

  [constants.PATCH_EVENT]: (state) => {
    state.event.patched.status = "fetching";
    state.event.patched.errors = {};
  },
  [constants.PATCH_EVENT_SUCCESS]: (state, { data }) => {
    state.event.patched.status = "success";
    state.event.patched.errors = {};
    state.event.patched.data = data;
  },
  [constants.PATCH_EVENT_ERROR]: (state, err) => {
    state.event.patched.status = "error";
    state.event.patched.errors = err;
  },
  [constants.DELETE_EVENT]: (state) => {
    state.event.deleted.status = "fetching";
    state.event.deleted.errors = {};
  },
  [constants.DELETE_EVENT_SUCCESS]: (state, data) => {
    state.event.deleted.status = "success";
    state.event.deleted.errors = {};
    state.event.deleted.data = data;
  },
  [constants.DELETE_EVENT_ERROR]: (state, err) => {
    state.event.deleted.status = "error";
    state.event.deleted.errors = err;
  },
  [constants.DELETE_EVENT_OCCS]: (state) => {
    state.occurrences.delete.status = "fetching";
    state.occurrences.delete.errors = {};
  },
  [constants.DELETE_EVENT_OCCS_SUCCESS]: (state) => {
    state.occurrences.delete.status = "success";
  },
  [constants.DELETE_EVENT_OCCS_ERROR]: (state, err) => {
    state.occurrences.delete.status = "error";
    state.occurrences.delete.errors = err;
  },
  [constants.GET_FREQUENCY_RULES]: (state) => {
    state.rules.status = "fetching";
    state.rules.errors = {};
  },
  [constants.GET_FREQUENCY_RULES_SUCCESS]: (state, { data }) => {
    state.rules.status = "success";
    state.rules.errors = {};
    state.rules.data = data;
  },
  [constants.GET_FREQUENCY_RULES_ERROR]: (state, err) => {
    state.rules.status = "error";
    state.rules.errors = err;
  },
  [constants.UPDATE_OCCURRENCE]: (state, occ) => {
    const day = moment(occ.start).format("YYYY-MM-DD");
    const foundIndex = state.occurrences.byDay[day].data.results.findIndex(
      (x) => x.uid == occ.uid
    );
    state.occurrences.byDay[day].data.results[foundIndex] = occ;
  },
  [constants.POST_OCCURRENCE]: (state) => {
    state.occurrences.post.status = "fetching";
    state.occurrences.post.errors = {};
  },
  [constants.POST_OCCURRENCE_SUCCESS]: (state, { data }) => {
    state.occurrences.post.status = "success";
    state.occurrences.post.errors = {};
    state.occurrences.post.data = data;
  },
  [constants.POST_OCCURRENCE_ERROR]: (state, err) => {
    state.occurrences.post.status = "error";
    state.occurrences.post.errors = err;
  },
  [constants.PATCH_OCCURRENCE]: (state) => {
    state.occurrences.patch.status = "fetching";
    state.occurrences.patch.errors = {};
  },
  [constants.PATCH_OCCURRENCE_SUCCESS]: (state, { data }) => {
    state.occurrences.patch.status = "success";
    state.occurrences.patch.errors = {};
    state.occurrences.patch.data = data;
  },
  [constants.PATCH_OCCURRENCE_ERROR]: (state, err) => {
    state.occurrences.patch.status = "error";
    state.occurrences.patch.errors = err;
  },
  [constants.DELETE_OCCURRENCE]: (state) => {
    state.occurrences.delete.status = "fetching";
    state.occurrences.delete.errors = {};
  },
  [constants.DELETE_OCCURRENCE_SUCCESS]: (state, { data }) => {
    state.occurrences.delete.status = "success";
    state.occurrences.delete.errors = {};
    state.occurrences.delete.data = data;
  },
  [constants.DELETE_OCCURRENCE_ERROR]: (state, err) => {
    state.occurrences.delete.status = "error";
    state.occurrences.delete.errors = err;
  },
  [constants.SYNC_SCHEDULE]: (state) => {
    state.sync.status = "fetching";
    state.sync.errors = {};
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
