<template>
  <div id="rcMainWrapper" class="rc-wrapper" tabindex="-1"></div>
</template>

<script>
export default {
  name: "RcTransitionLayout"
};
</script>


