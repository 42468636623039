import app from "#/app";
import moment from "moment";

export default function rcBroadcastChannelApi() {
  return (store) => {
    let bc = null;
    let team_uid = null;
    let sharingInProgress = false;

    store.subscribe((mutation) => {
      if (sharingInProgress) {
        return;
      }
      if (mutation.type === "AUTH_SUCCESS") {
        team_uid = mutation.payload.auth.team_uid;

        // create broadcast channel based on team_uid
        bc = new BroadcastChannel(team_uid);
        bc.onmessage = ({ data: m }) => {
          try {
            sharingInProgress = true;
            const workingRoute = m?.callback?.route;
            const viewingRoute = app.$router.currentRoute.name;

            if (m.type === "SYNC_SCHEDULE") {
              // working and viewing routes are the same
              if (workingRoute == viewingRoute) {
                const params = {
                  ...app.$router.currentRoute.query,
                  ...app.$router.currentRoute.params
                };
                store.dispatch(m.callback.action, params);
              }
              // working route is schedule
              // viewing route is monitor
              else if (
                workingRoute == "fastChannel.week" &&
                viewingRoute == "fastChannel.monitor"
              ) {
                const params = {
                  day:
                    app.$router.currentRoute.query.day ||
                    moment().format("YYYY-MM-DD"),
                  ...app.$router.currentRoute.params
                };
                store.dispatch("GET_FAST_CHANNEL_DAY", params);
              }

              // working route is detail
              // viewing route is schedule
              else if (
                [
                  "catalog.episodic.detail",
                  "catalog.streams.detail",
                  "user.playlists.detail"
                ].includes(workingRoute) &&
                viewingRoute == "fastChannel.week"
              ) {
                store.dispatch(m.callback.action, {
                  ...app.$router.currentRoute.query,
                  ...app.$router.currentRoute.params
                });
              }
            }

            if (m.type == "DELETE_EVENT_SUCCESS") {
              // working route is week
              // viewing route is detail
              // deleted uid matches detail uid
              if (
                workingRoute == "fastChannel.week" &&
                [
                  "catalog.episodic.detail",
                  "catalog.streams.detail",
                  "user.playlists.detail"
                ].includes(viewingRoute) &&
                app.$router.currentRoute.params.uid == m.callback.params.uid
              ) {
                store.dispatch(m.callback.action, m.callback.params);
              }
            }
          } finally {
            sharingInProgress = false;
          }
        };
      }
      if (bc && mutation.type === "SYNC_SCHEDULE") {
        const callback = {
          route: app.$router.currentRoute.name,
          action: "GET_FAST_CHANNEL_WEEK"
        };
        bc.postMessage({ ...mutation, callback });
      }
      if (bc && mutation.type === "DELETE_EVENT_SUCCESS") {
        const callback = {
          ...mutation.payload.callback,
          route: app.$router.currentRoute.name
        };
        bc.postMessage({ ...mutation, callback });
      }
    });
  };
}
