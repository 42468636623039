import axios from "@/utils/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    list: {
      data: { results: [] },
      status: "",
      errors: ""
    },
    detail: {
      data: {},
      status: "",
      errors: ""
    },
    posted: {
      status: "",
      errors: ""
    },
    deleted: {
      status: "",
      errors: ""
    },
    patched: {
      status: "",
      errors: ""
    },
    reordered: {
      status: "",
      errors: ""
    },
    modal: {
      status: "",
      errors: "",
      mode: null,
      playlist: null,
      playlists: [],
      item: null
    },
    segment: {
      data: {},
      status: "",
      errors: ""
    },
    segmentToEdit: { item: {} }
  };
}

const getters = {
  getPlaylistDetail: (state) => state.detail.data,
  getPlaylistByUID: (state) => (uid) => {
    return state.list.data.results.find((obj) => obj.uid == uid);
  }
};

const actions = {
  [constants.GET_PLAYLISTS]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      commit(constants.GET_PLAYLISTS);
      axios
        .get(`users/${user_uid}/playlists`, { params: { page_size: 500 } })
        .then((response) => {
          commit(constants.GET_PLAYLISTS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_PLAYLISTS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.POST_PLAYLIST]: ({ commit, rootState }, payload) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      commit(constants.POST_PLAYLIST);
      axios
        .post(`users/${user_uid}/playlists`, payload)
        .then((response) => {
          commit(constants.POST_PLAYLIST_SUCCESS, {
            ...response,
            metadata: { ...payload }
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.POST_PLAYLIST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_PLAYLIST]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      commit(constants.GET_PLAYLIST);
      const url = params.isPublic
        ? `playlists/${params.uid}`
        : `users/${user_uid}/playlists/${params.uid}`;

      if (params.uid) {
        axios
          .get(url)
          .then((response) => {
            commit(constants.GET_PLAYLIST_SUCCESS, response);
            resolve(response);
          })
          .catch((error) => {
            commit(constants.GET_PLAYLIST_ERROR, error);
            reject(error);
          });
      } else {
        commit(constants.GET_PLAYLIST_ERROR, {});
        reject({});
      }
    });
  },
  [constants.DELETE_PLAYLIST]: ({ commit, rootState }, playlist_uid) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      commit(constants.DELETE_PLAYLIST);
      axios
        .delete(`users/${user_uid}/playlists/${playlist_uid}`)
        .then((response) => {
          commit(constants.DELETE_PLAYLIST_SUCCESS, playlist_uid);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.DELETE_PLAYLIST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.PATCH_PLAYLIST]: ({ commit, rootState }, params) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      const playlist_uid = params.playlist_uid;
      delete params.playlist_uid;
      commit(constants.PATCH_PLAYLIST);
      axios
        .patch(`users/${user_uid}/playlists/${playlist_uid}`, params)
        .then((response) => {
          commit(constants.PATCH_PLAYLIST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.PATCH_PLAYLIST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.PATCH_ADD_SCREENERS]: ({ commit, rootState }, payload) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      commit(constants.PATCH_ADD_SCREENERS);
      axios
        .post(`users/${user_uid}/segments/create`, payload)
        .then((response) => {
          commit(constants.PATCH_ADD_SCREENERS_SUCCESS, {
            ...payload
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.PATCH_ADD_SCREENERS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.DELETE_SEGMENT]: (
    { commit, dispatch, state, rootState },
    params
  ) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      const playlist_uid = state.detail.data.uid;
      const segment_uid = params.segment_uid;
      commit(constants.DELETE_SEGMENT);

      axios
        .delete(`users/${user_uid}/segments/${segment_uid}`)
        .then((response) => {
          commit(constants.DELETE_SEGMENT_SUCCESS, response);
          dispatch(constants.GET_PLAYLIST, {
            uid: playlist_uid,
            isPublic: false
          });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.DELETE_SEGMENT_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.OPEN_PLAYLIST_MODAL]: ({ commit }, params) => {
    return new Promise((resolve) => {
      commit(constants.OPEN_PLAYLIST_MODAL, params);
      resolve(params);
    });
  },
  [constants.REORDER_PLAYLIST]: ({ commit, state, rootState }, params) => {
    return new Promise((resolve, reject) => {
      commit(constants.REORDER_PLAYLIST, params);
      const user_uid = rootState.auth.user.user_uid;
      const playlist_uid = state.detail.data.uid;
      const payload = {
        name: state.detail.data.name,
        segments_ids: state.detail.data.segments.map((s) => s.uid)
      };

      axios
        .patch(`users/${user_uid}/playlists/${playlist_uid}/reorder`, payload)
        .then((response) => {
          commit(constants.REORDER_PLAYLIST_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.REORDER_PLAYLIST_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.PATCH_SEGMENT]: ({ commit, state, rootState }, payload) => {
    return new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      const segment_uid = state.segmentToEdit.uid;

      commit(constants.PATCH_SEGMENT);
      axios
        .patch(`users/${user_uid}/segments/${segment_uid}`, payload)
        .then((response) => {
          commit(constants.PATCH_SEGMENT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.PATCH_SEGMENT_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_PLAYLISTS]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
    state.list.data = getDefaultState().list.data;
  },
  [constants.GET_PLAYLISTS_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data = data;
  },
  [constants.GET_PLAYLISTS_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },
  [constants.GET_PLAYLIST]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
    state.detail.data = {};
  },
  [constants.GET_PLAYLIST_SUCCESS]: (state, { data }) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },
  [constants.GET_PLAYLIST_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = err;
  },
  [constants.POST_PLAYLIST]: (state) => {
    state.posted.status = "fetching";
    state.posted.errors = {};
  },
  [constants.POST_PLAYLIST_SUCCESS]: (state, { data }) => {
    state.posted.status = "success";
    state.posted.errors = {};
    state.list.data.results.push(data);
  },
  [constants.POST_PLAYLIST_ERROR]: (state, err) => {
    state.posted.status = "error";
    state.posted.errors = err;
  },
  [constants.DELETE_PLAYLIST]: (state) => {
    state.deleted.status = "fetching";
    state.deleted.errors = {};
  },
  [constants.DELETE_PLAYLIST_SUCCESS]: (state, uid) => {
    state.deleted.status = "success";
    state.deleted.errors = {};
    state.list.data.results = state.list.data.results.filter(
      (p) => p.uid !== uid
    );
  },
  [constants.DELETE_PLAYLIST_ERROR]: (state, err) => {
    state.deleted.status = "error";
    state.deleted.errors = err;
  },
  [constants.PATCH_PLAYLIST]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
  },
  [constants.PATCH_PLAYLIST_SUCCESS]: (state, { data }) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },
  [constants.PATCH_PLAYLIST_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = err;
  },
  [constants.PATCH_ADD_SCREENERS]: (state) => {
    state.patched.status = "fetching";
    state.patched.errors = {};
  },
  [constants.PATCH_ADD_SCREENERS_SUCCESS]: (state, { data }) => {
    state.patched.status = "success";
    state.patched.errors = {};
    state.patched.data = data;
  },
  [constants.PATCH_ADD_SCREENERS_ERROR]: (state, err) => {
    state.patched.status = "error";
    state.patched.errors = err;
  },
  [constants.DELETE_SEGMENT]: (state) => {
    state.patched.status = "fetching";
    state.patched.errors = {};
  },
  [constants.DELETE_SEGMENT_SUCCESS]: (state, { data }) => {
    state.patched.status = "success";
    state.patched.errors = {};
    state.patched.data = data;
  },
  [constants.DELETE_SEGMENT_ERROR]: (state, err) => {
    state.patched.status = "error";
    state.patched.errors = err;
  },
  [constants.OPEN_PLAYLIST_MODAL]: (state, params) => {
    state.modal.mode = params.mode;
    state.modal.item = params.item;
    state.modal.items = params.items;
    state.modal.playlist = params.playlist;
  },
  [constants.SET_MODAL_MODE]: (state, mode) => {
    state.modal.mode = mode;
  },
  [constants.REORDER_PLAYLIST]: (state, params) => {
    if (params.ordered) {
      state.detail.data.segments = params.ordered;
    } else {
      const to = params.to;
      const from = params.from;
      const arr = state.detail.data.segments;

      if (to < 0 || to > arr.length) return;
      const element = arr[from];
      arr.splice(from, 1);
      arr.splice(to, 0, element);
    }
  },
  [constants.REORDER_PLAYLIST_SUCCESS]: (state) => {
    state.reordered.status = "success";
    state.reordered.errors = {};
  },
  [constants.REORDER_PLAYLIST_ERROR]: (state, err) => {
    state.reordered.status = "error";
    state.reordered.errors = err;
  },
  [constants.SET_SEGMENT_TO_EDIT]: (state, segment) => {
    state.segmentToEdit = segment;
  },
  [constants.PATCH_SEGMENT]: (state) => {
    state.segment.status = "fetching";
    state.segment.errors = {};
  },
  [constants.PATCH_SEGMENT_SUCCESS]: (state, { data }) => {
    state.segment.status = "success";
    state.segment.errors = {};
    state.segment.data = data;

    let foundIndex = state.detail.data.segments.findIndex(
      (s) => s.uid == data.uid
    );

    delete data.screener;

    const newSegment = {
      ...state.detail.data.segments[foundIndex],
      ...data
    };

    state.detail.data.segments[foundIndex] = newSegment;
    state.segmentToEdit = newSegment;
  },
  [constants.PATCH_SEGMENT_ERROR]: (state, err) => {
    state.segment.status = "error";
    state.segment.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
