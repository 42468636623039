import axios from "@/utils/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    search: {
      data: [],
      status: null,
      error: null
    },
    imports: {
      data: [],
      status: null,
      error: null
    },
    list: {
      data: { results: [] },
      status: null,
      error: null
    },
    detail: {
      data: {},
      status: null,
      error: null
    },
    takedown: {
      data: {},
      status: null,
      error: null
    }
  };
}
const actions = {
  [constants.POST_IMPORT_ITEMS]: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit(constants.POST_IMPORT_ITEMS);
      axios
        .post(`items/import`, payload)
        .then((response) => {
          commit(constants.POST_IMPORT_ITEMS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.POST_IMPORT_ITEMS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_IMPORTERS]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_IMPORTERS);
      axios
        .get(`importer-searches`)
        .then((response) => {
          commit(constants.GET_IMPORTERS_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_IMPORTERS_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.GET_IMPORTER]: ({ commit }, uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_IMPORTER);
      axios
        .get(`importer-searches/${uid}`)
        .then((response) => {
          commit(constants.GET_IMPORTER_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_IMPORTER_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.PUT_IMPORT_TAKEDOWN]: ({ commit }, doc_id) => {
    return new Promise((resolve, reject) => {
      commit(constants.PUT_IMPORT_TAKEDOWN);
      //importer-searches/results/mpx.document.1997268035508/takedown
      axios
        .put(`importer-searches/results/${doc_id}/takedown`)
        .then((response) => {
          commit(constants.PUT_IMPORT_TAKEDOWN_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.PUT_IMPORT_TAKEDOWN_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.POST_IMPORT_ITEMS]: (state) => {
    state.imports.status = "fetching";
    state.imports.errors = {};
  },
  [constants.POST_IMPORT_ITEMS_SUCCESS]: (state, { data }) => {
    state.imports.status = "success";
    state.imports.errors = {};
    state.imports.data = data;
  },
  [constants.POST_IMPORT_ITEMS_ERROR]: (state, err) => {
    state.imports.status = "error";
    state.imports.errors = err;
  },
  [constants.GET_IMPORTERS]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
  },
  [constants.GET_IMPORTERS_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data = data;
  },
  [constants.GET_IMPORTERS_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },
  [constants.GET_IMPORTER]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
  },
  [constants.GET_IMPORTER_SUCCESS]: (state, { data }) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },
  [constants.GET_IMPORTER_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = err;
  },
  [constants.PUT_IMPORT_TAKEDOWN]: (state) => {
    state.takedown.status = "fetching";
    state.takedown.errors = {};
  },
  [constants.PUT_IMPORT_TAKEDOWN_SUCCESS]: (state, { data }) => {
    state.takedown.status = "success";
    state.takedown.errors = {};
    state.takedown.data = data;
  },
  [constants.PUT_IMPORT_TAKEDOWN_ERROR]: (state, err) => {
    state.takedown.status = "error";
    state.takedown.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
