import i18n from "#/locales";

function siteLoad(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `#/pages/${view}.vue`);
}

const routes = [
  {
    path: "login",
    alias: "connexion",
    component: siteLoad("auth/Login"),
    name: "auth.login",
    meta: {
      title: "pageMeta.login.title",
      alias: [null, null, "connexion"],
      cssClass: "rc-login-page"
    }
  },
  {
    path: "login/pin",
    alias: "connexion/numero-identification",
    component: siteLoad("auth/LoginPin"),
    name: "auth.login.pin",
    meta: {
      title: "pageMeta.pin.title",
      alias: [null, null, "connexion", "numero-identification"]
    }
  },
  {
    path: "access-denied",
    name: "403",
    alias: "acces-interdit",
    component: siteLoad("auth/AccessDenied"),
    meta: {
      alias: [null, null, "acces-interdit"],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.t("a11y.nav.access_denied.title"),
            active: true
          }
        ]
      }
    }
  }
];

export default routes;
