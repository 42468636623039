import axios from "@/utils/axios.js";
import * as constants from "./actions";

// TODO: remove cross store imports
import {
  AUTH_REQUEST_CREATE_ORGANISATION,
  AUTH_SET_USER
} from "@/libvue/apps/auth/actions";

function getDefaultState() {
  return {
    errors: {},
    status: ""
  };
}

const actions = {
  [constants.USER_GET_PROFILE_REQUEST]: ({ commit, rootState }) =>
    new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      commit(constants.USER_GET_PROFILE_REQUEST);
      axios
        .get(`users/${user_uid}`)
        .then((response) => {
          commit(AUTH_SET_USER, response.data);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.USER_GET_PROFILE_REQUEST_ERROR, error);
          reject(error);
        });
    }),
  [constants.USER_SET_PROFILE_REQUEST]: ({ commit, rootState }, data) =>
    new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      commit(constants.USER_SET_PROFILE_REQUEST);
      axios
        .patch(`users/${user_uid}/set-profile`, data)
        .then((response) => {
          commit(AUTH_SET_USER, response.data);

          resolve(response);
        })
        .catch((error) => {
          commit(constants.USER_SET_PROFILE_REQUEST_ERROR, error);
          reject(error);
        });
    }),
  [constants.USER_ORGANISATION_PATCH_REQUEST]: ({ commit }, data) =>
    new Promise((resolve, reject) => {
      commit(constants.USER_ORGANISATION_PATCH_REQUEST);

      axios
        .post(`register`, data)
        .then((response) => {
          commit(constants.USER_ORGANISATION_PATCH_REQUEST_SUCCESS, response);
          commit(AUTH_SET_USER, response.data);
          commit(AUTH_REQUEST_CREATE_ORGANISATION, { response, data: data });
          resolve(response);
        })
        .catch((error) => {
          commit(constants.USER_ORGANISATION_PATCH_REQUEST_ERROR, error);
          reject(error);
        });
    }),
  [constants.USER_ORGANISATION_REQUEST]: ({ commit }) =>
    new Promise((resolve, reject) => {
      commit(constants.USER_ORGANISATION_REQUEST);

      axios
        .get(`register`)
        .then((response) => {
          commit(constants.USER_ORGANISATION_REQUEST_SUCCESS, response);
          commit(AUTH_SET_USER, response.data);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.USER_ORGANISATION_REQUEST_ERROR, error);
          reject(error);
        });
    })
};

const mutations = {
  [constants.USER_SET_PROFILE_REQUEST]: (state) => {
    state.status = "user set name request";
    state.errors = {};
  },
  [constants.USER_SET_PROFILE_REQUEST_ERROR]: (state, resp) => {
    state.status = "error";
    state.errors = resp.response.data;
  },
  [constants.USER_GET_PROFILE_REQUEST]: (state) => {
    state.status = "user set name request";
    state.errors = {};
  },
  [constants.USER_GET_PROFILE_REQUEST_ERROR]: (state, resp) => {
    state.status = "error";
    state.errors = resp.response.data;
  },
  [constants.USER_ORGANISATION_PATCH_REQUEST]: (state) => {
    state.status = "user organisation";
    state.errors = {};
  },
  [constants.USER_ORGANISATION_PATCH_REQUEST_SUCCESS]: (state) => {
    state.status = "success";
    state.errors = {};
  },
  [constants.USER_ORGANISATION_PATCH_REQUEST_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  [constants.USER_ORGANISATION_REQUEST]: (state) => {
    state.status = "get user organisation";
    state.errors = {};
  },
  [constants.USER_ORGANISATION_REQUEST_SUCCESS]: (state) => {
    state.status = "success";
    state.errors = {};
  },
  [constants.USER_ORGANISATION_REQUEST_ERROR]: (state, errors) => {
    state.status = "error";
    state.errors = Object.assign({}, errors.response.data);
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
