<template>
  <div
    v-if="isVisible"
    class="rc-alert-cookie alert alert-light"
    role="alert"
    aria-live="polite"
    aria-atomic="true"
  >
    <h2 class="h3">{{ $t("cookie.title") }}</h2>
    <p>
      {{ $t("cookie.message") }}
    </p>
    <!-- Voir si lien est le bon -->
    <a :href="$t('nav.url.cookies')">{{ $t("cookie.link_manage") }}</a>
    <button
      class="btn btn-primary rc-btn-accept d-block"
      @click="closeCookie()"
    >
      {{ $t("cookie.cta") }}
    </button>
  </div>
</template>

<script>
export default {
  name: "RcAlertCookie",
  data() {
    return {
      isVisible:
        localStorage.getItem("cookie-alert") != null
          ? JSON.parse(localStorage.getItem("cookie-alert"))
          : true
    };
  },

  methods: {
    closeCookie() {
      localStorage.setItem("cookie-alert", false);
      this.isVisible = false;
    }
  }
};
</script>

<style lang="scss">
.rc-alert-cookie {
  position: fixed !important;
  bottom: 0;
  margin: 1.25rem !important;
  max-width: 340px;
  z-index: 2000;
  color: black !important;
  background-color: white !important;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  outline: 0;
  padding: 1.25rem !important;

  .rc-btn-accept {
    margin-top: 1rem;
    width: 100%;
  }
}
</style>
