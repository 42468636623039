import axios from "@/utils/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    detail: {
      data: {},
      status: "",
      errors: ""
    }
  };
}

const actions = {
  [constants.GET_SCREENER]: ({ commit }, uid) => {
    return new Promise((resolve, reject) => {
      commit(constants.GET_SCREENER);
      axios
        .get(`screeners/${uid}`)
        .then((response) => {
          commit(constants.GET_SCREENER_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_SCREENER_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_SCREENER]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
  },
  [constants.GET_SCREENER_SUCCESS]: (state, { data }) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },
  [constants.GET_SCREENER_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
