const Scopes = {
  install(Vue) {
    Vue.prototype.$user = {
      can: (permission, profile) => profile.scopes.includes(permission),
      isAdmin: (profile) => profile.role.includes("admin"),
      isBuyer: (profile) => profile.role.includes("buyer"),
      isSeller: (profile) => profile.role.includes("seller"),
      isItemOwner: (item, profile) => profile.team_uid === item.owner.uid
    };
  }
};

export default Scopes;
