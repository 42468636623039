<template>
  <b-modal :id="modalId" headerClass="border-bottom-0" @show="onShow">
    <template #modal-header>
      <div></div>
      <button
        class="btn btn-link rc-btn-icon rc-btn-close"
        @click="$bvModal.hide(modalId)"
      >
        <span class="sr-only">{{ $t("common.close") }}</span>
        <RcIcon name="close"></RcIcon>
      </button>
    </template>

    <h2 class="text-center h3">{{ msg }}</h2>
    <template #modal-footer>
      <div class="row justify-content-between align-items-center w-100">
        <div class="col-8">
          <div class="custom-control custom-checkbox">
            <input
              id="ckb_dontRemind"
              v-model="hideWarning"
              type="checkbox"
              class="custom-control-input"
            /><label class="custom-control-label" for="ckb_dontRemind">
              {{ $t("common.dont_remind_me") }}</label
            >
          </div>
        </div>
        <div class="col text-right">
          <button class="btn btn-primary" @click="handleOk()">
            {{ $t(`common.continue`) }}
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Config from "#/config";
import RcIcon from "@/libvuePure/components/icons/Base.vue";

export default {
  name: "RcExternalLinkModal",

  components: {
    RcIcon
  },

  data() {
    return {
      modalId: "externalLinkModal",
      href: null,
      localHide: localStorage.getItem("external-link-warning")
        ? localStorage.getItem("external-link-warning")
        : false
    };
  },
  computed: {
    msg() {
      return this.$t("common.leave_website", { name: Config.site.name });
    },
    hideWarning: {
      get: function () {
        return this.localHide;
      },
      set: function (value) {
        this.localHide = value;
        localStorage.setItem("external-link-warning", value);
      }
    }
  },
  methods: {
    onShow(e) {
      this.href = e.vueTarget.$_returnFocus.href;

      if (this.hideWarning) {
        e.preventDefault();
        window.open(this.href, "_self");
      }
    },
    handleOk() {
      window.open(this.href, "_self");
    }
  }
};
</script>
