import * as constants from "./actions";

function getDefaultState() {
  return {
    selectables: {},
    drawer: {
      right: { visible: false, widthRatio: "third" }
    },
    archives: {
      selected: [],
      showSelected: false,
      keyword: "",
      search: { text: "", value: "" },
      filters: {
        archived_on: null,
        archive_name: null,
        archive_ext: null,
        archive_has_identify: null,
        relative_status: null
      },
      autoRefresh: {
        checked: false,
        counter: ""
      },
      collapse: {
        filters: false
      }
    },
    upload: {
      selected: [],
      showSelected: false
    },

    flow: {
      keyword: "",
      search: { text: "", value: "" },
      autoRefresh: {
        checked: false,
        counter: ""
      },
      filters: {
        updated_on: { value: "updated_on=Last 7 days", text: "Last 7 days" },
        flow_name: null,
        regulator_names: null,
        regulator_status: null,
        relative_status: null,
        relative_time: null
      },
      collapse: {
        filters: false
      }
    },
    deliveries: {
      keyword: "",
      multiselect: [],
      search: { text: "", value: "" },
      autoRefresh: {
        checked: false,
        counter: ""
      },
      filters: {
        delivery_modified_on: null,
        packager_name: null,
        status: null,
        distribution_start: null
      },
      collapse: {
        filters: false
      }
    },
    favorites: {
      showSelected: false,
      selected: []
    },
    playlists: {
      showSelected: false,
      selected: []
    }
  };
}

const getters = {
  getSelectables: (state) => state.selectables
};

const actions = {};

const mutations = {
  [constants.LIB_SELECTABLES_SET]: (state, payload) => {
    state.status = "success";
    state.errors = {};
    state.selectables = { ...state.selectables, ...payload };
  },
  [constants.LIB_DRAWER_SET_STATE]: (state, payload) => {
    if (payload == "toggle") {
      state.drawer.right.visible = !state.drawer.right.visible;
    } else {
      state.drawer.right = payload;
    }
  },
  [constants.LIB_KEYWORD_SET]: (state, payload) => {
    state[payload.context].keyword = payload.value;
  },
  [constants.LIB_FILTERS_SET]: (state, payload) => {
    state[payload.context].filters[payload.filterType] = payload.value;
  },
  [constants.LIB_SEARCH_SET]: (state, payload) => {
    state[payload.context].search = payload;
  },
  [constants.LIB_FILTER_RESET]: (state, context) => {
    // using local copy to avoid mutating filters on every iteration
    const localFilters = { ...state[context].filters };

    Object.keys(localFilters).forEach((filter) => {
      localFilters[filter] = null;
    });

    state[context].filters = localFilters;
  },
  [constants.LIB_AUTO_REFRESH_SET]: (state, payload) => {
    state[payload.context].autoRefresh[payload.key] = payload.value;
  },
  [constants.LIB_MULTISELECT_SET]: (state, payload) => {
    if (payload.reset) {
      state[payload.context].selected = [];
      state[payload.context].showSelected = false;
    }
    if (
      payload.checked &&
      !state[payload.context].selected.includes(payload.value)
    ) {
      state[payload.context].selected.push(payload.value);
    }
    if (!payload.checked) {
      state[payload.context].selected = state[payload.context].selected.filter(
        (v) => v !== payload.value
      );
    }
  },
  [constants.LIB_COLLAPSE_SET_STATE]: (state, payload) => {
    state[payload.context].collapse[payload.key] = payload.value;
  },
  [constants.LIB_TOGGLE_SHOW_SELECTED]: (state, payload) => {
    state[payload.context].showSelected = payload.value;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
