export const GET_FAVORITES = "GET_FAVORITES";
export const GET_FAVORITES_SUCCESS = "GET_FAVORITES_SUCCESS";
export const GET_FAVORITES_ERROR = "GET_FAVORITES_ERROR";

export const GET_FAVORITE = "GET_FAVORITE";
export const GET_FAVORITE_SUCCESS = "GET_FAVORITE_SUCCESS";
export const GET_FAVORITE_ERROR = "GET_FAVORITE_ERROR";

export const POST_FAVORITE = "POST_FAVORITE";
export const POST_FAVORITE_SUCCESS = "POST_FAVORITE_SUCCESS";
export const POST_FAVORITE_ERROR = "POST_FAVORITE_ERROR";

export const DELETE_FAVORITE = "DELETE_FAVORITE";
export const DELETE_FAVORITE_SUCCESS = "DELETE_FAVORITE_SUCCESS";
export const DELETE_FAVORITE_ERROR = "DELETE_FAVORITE_ERROR";

export const DELETE_ALL_FAVORITES = "DELETE_ALL_FAVORITES";
export const DELETE_ALL_FAVORITES_SUCCESS = "DELETE_ALL_FAVORITES_SUCCESS";
export const DELETE_ALL_FAVORITES_ERROR = "DELETE_ALL_FAVORITES_ERROR";

export const FAVORITE_APPEND_NEXT = "FAVORITE_APPEND_NEXT";
export const FAVORITE_APPEND_NEXT_SUCCESS = "FAVORITE_APPEND_NEXT_SUCCESS";
export const FAVORITE_APPEND_NEXT_ERROR = "FAVORITE_APPEND_NEXT_ERROR";
