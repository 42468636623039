import store from "#/stores";

const getUserInfo = (userProfile) => {
  return {
    user_uid: userProfile.user_uid ? userProfile.user_uid : "",
    user_role: userProfile.role ? userProfile.role : "",
    user_scopes: userProfile.scopes ? userProfile.scopes : []
  };
};
const getPageInfo = (route) => {
  return {
    page_url: route.fullPath ? route.fullPath : "",
    page_title: document.title,
    page_params: route.params ? route.params : {}
  };
};
const getSearchInfo = (state) => {
  return {
    search_type: store.getters.appliedFiltersLabels.length
      ? "filtered"
      : "simple",
    search_terms: state.catalog.query ? state.catalog.query : "",
    search_filters: store.getters.appliedFiltersLabels
  };
};
const getFiltersInfo = (state) => {
  const search_filters = store.getters.formatUserFilters
    ? [{ ...store.getters.getFilterParams, ...store.getters.formatUserFilters }]
    : [store.getters.getFilterParams];

  const search_terms = state.items.filterParams.query
    ? state.items.filterParams.query
    : "";

  return {
    search_terms,
    search_filters
  };
};
const getTeamInfo = (profile) => {
  return {
    team_uid: profile?.team_uid ? profile.team_uid : null,
    organisation_uid: profile?.organisation_uid
      ? profile.organisation_uid
      : null,
    organisation_name: profile?.organisation_name
      ? profile.organisation_name
      : null,
    team_name: profile?.team_name ? profile.team_name : null,
    team_metadata: {
      age_groups: store.getters.getTeamAgeGroupNames
        ? store.getters.getTeamAgeGroupNames
        : null,
      province: store.getters.getTeamProvinceFromCurricula
        ? store.getters.getTeamProvinceFromCurricula.find((p) => p)
        : null
    }
  };
};

export { getUserInfo, getPageInfo, getTeamInfo, getSearchInfo, getFiltersInfo };
