import { mapActions, mapState } from "vuex";
import { AUTH_REFRESH } from "@/libvue/apps/auth/actions";
import { TEAM_JOIN_REQUEST, TEAM_SWITCH } from "@/libvue/apps/team/actions";
import store from "#/stores";
import Config from "#/config";

const teamMixin = {
  data() {
    return {
      form: {},
      initial: {},
      statusMap: {
        revoked: { variant: "danger", display: "Revoked", action: null },
        pending: {
          variant: "info",
          display: "Pending approval",
          action: null
        },
        approved: {
          variant: "primary",
          display: "Switch to...",
          action: this.switchToTeam.bind()
        },
        join: {
          variant: "success",
          display: "Join Team",
          action: this.joinTeam.bind()
        }
      }
    };
  },

  computed: {
    isAdmin() {
      return this.$user.isAdmin(this.profile);
    },
    organisationTeams() {
      return this.organisation.teams.data.results;
    },
    teamsUserIsJoined() {
      return this.memberships.filter((t) => t.status == "approved");
    },
    teamsUserCanJoin() {
      return this.organisation.teams.data.results.filter((t) =>
        ["pending", "join"].includes(this.getMembershipStatus(t))
      );
    },
    teamUserJoinedAllOrgTeams() {
      return this.organisationTeams.length === 0
        ? false
        : this.organisationTeams.length === this.teamsUserIsJoined.length;
    },
    siteName() {
      return Config.site.name;
    },
    teacherTeam() {
      return this.organisationTeams.find((t) => t.team_type == "teachers");
    },
    librariesTeam() {
      return this.organisationTeams.find((t) => t.team_type == "libraries");
    },
    ...mapState({
      current: (state) => state.team.current,
      profile: (state) => state.auth.user,
      teamProfile: (state) => state.team.profile,
      memberships: (state) => state.team.profile.memberships,
      organisation: (state) => state.team.organisation
    })
  },

  methods: {
    isJoined(team) {
      return this.memberships.length
        ? this.memberships.some((m) => {
            let teamUid = m.team.uid ? m.team.uid : m.team;
            return teamUid == team.uid;
          })
        : false;
    },
    isDefault(team) {
      return this.memberships.length
        ? this.memberships.some((m) => {
            let teamUid = m.team.uid ? m.team.uid : m.team;
            if (teamUid == team.uid) {
              return m.default;
            }
          })
        : false;
    },
    isSignedIn(team) {
      return team?.uid == this.profile?.team_uid;
    },
    joinTeam(team) {
      return this.join({ team: team.uid })
        .then((res) => {
          if (res.data.status == "approved") {
            const membership = { team: { uid: res.data.team } };
            store.dispatch(AUTH_REFRESH, membership).then(() => {
              this.$notify.success(
                this.$t("team.request_approved", { name: res.data.name })
              );
            });
          } else {
            this.$notify.success(
              this.$t("team.request_sent", { name: res.data.name })
            );
          }
        })
        .catch(() => this.$notify.danger(this.$t("team.request_error")));
    },
    switchToTeam(team, route) {
      return this.switch({ team: { uid: team.uid }, route });
    },
    getMembershipStatus(team) {
      const membership = this.memberships.find((m) => {
        let teamUid = m.team?.uid ? m.team?.uid : m.team;
        return teamUid == team?.uid;
      });
      return membership ? membership.status : "join";
    },
    getMembership(uid) {
      const membership = this.memberships.find((m) => {
        let teamUid = m.team?.uid ? m.team?.uid : m.team;
        return teamUid == uid;
      });
      return membership;
    },
    onTeamClick(team) {
      if (this.isSignedIn(team)) {
        return;
      }

      const status = this.statusMap[this.getMembershipStatus(team)];

      if (status.action) {
        status.action(team, this.$router.currentRoute.name).then((res) => {
          this.$emit("change", res);
        });
      }
    },
    ...mapActions({
      join: TEAM_JOIN_REQUEST,
      switch: TEAM_SWITCH
    })
  }
};

export { teamMixin };
