import { ifAuthenticated } from "#/stores/auth";
import i18n from "#/locales";
import store from "#/stores";

function load(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `#/pages/${view}.vue`);
}

const routes = [
  {
    path: "my-playlists",
    alias: "mes-listes-de-lecture",
    name: "user.playlists",
    props: (route) => ({ query: route.query.q }),
    component: () => import(`#/pages/catalog/PlaylistList.vue`),
    beforeEnter: ifAuthenticated,
    meta: {
      title: "pageMeta.playlists.title",
      alias: [null, null, "mes-listes-de-lecture"],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.t("nav.playlists"),
            active: true
          }
        ]
      }
    }
  },
  {
    path: "my-playlists/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "mes-listes-de-lecture/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "user.playlists.detail",
    component: load("playlists/Detail"),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "mes-listes-de-lecture", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.t("nav.playlists"),
            to: { name: "user.playlists" }
          },
          {
            text: () => i18n.t("catalog.playlist_detail"),
            active: true
          }
        ]
      }
    }
  },
  {
    path: "playlists/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    alias:
      "listes-de-lecture/:uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    name: "public.playlists.detail",
    component: load("playlists/DetailPublic"),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "listes-de-lecture", null],
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.t("nav.playlists"),
            to: { name: "user.playlists" }
          },
          {
            text: () => store.getters.getPlaylistDetail.name,
            active: true
          }
        ]
      }
    }
  }
];
export default routes;
