<template>
  <footer role="contentinfo" class="rc-footer">
    <div class="container-fluid">
      <h2 class="sr-only">{{ $t("a11y.nav.footer") }}</h2>
      <div class="row">
        <div class="col">
          {{ $t("common.all_rights_reserved") }}
          <span>&copy; CBC/Société Radio‑Canada {{ year }}.</span>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "RcSiteFooter",
  computed: {
    year() {
      const today = new Date();
      return today.getFullYear();
    }
  }
};
</script>
