export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH = "AUTH_REFRESH";

export const AUTH_VERIFY = "AUTH_VERIFY";
export const AUTH_VERIFY_SUCCESS = "AUTH_VERIFY_SUCCESS";
export const AUTH_VERIFY_ERROR = "AUTH_VERIFY_ERROR";

export const AUTH_GET_IP_TOKEN = "AUTH_GET_IP_TOKEN";
export const AUTH_GET_IP_TOKEN_SUCCESS = "AUTH_GET_IP_TOKEN_SUCCESS";
export const AUTH_GET_IP_TOKEN_ERROR = "AUTH_GET_IP_TOKEN_ERROR";

// verify if we need
export const AUTH_REQUEST_CREATE_ORGANISATION =
  "AUTH_REQUEST_CREATE_ORGANISATION";

export const AUTH_RESET = "AUTH_RESET";

export const AUTH_REQUEST_PIN = "AUTH_REQUEST_PIN";
export const AUTH_REQUEST_PIN_SUCCESS = "AUTH_REQUEST_PIN_SUCCESS";
export const AUTH_REQUEST_PIN_ERROR = "AUTH_REQUEST_PIN_ERROR";

export const AUTH_REQUEST_TOKEN = "AUTH_REQUEST_TOKEN";
export const AUTH_REQUEST_TOKEN_SUCCESS = "AUTH_REQUEST_TOKEN_SUCCESS";
export const AUTH_REQUEST_TOKEN_ERROR = "AUTH_REQUEST_TOKEN_ERROR";

export const AUTH_GET_SESSION_TOKEN = "AUTH_GET_SESSION_TOKEN";

export const AUTH_SET_USER = "AUTH_SET_USER";

export const AUTH_SET_AGREEMENTS = "AUTH_SET_AGREEMENTS";
export const AUTH_SET_AGREEMENTS_SUCCESS = "AUTH_SET_AGREEMENTS_SUCCESS";
export const AUTH_SET_AGREEMENTS_ERROR = "AUTH_SET_AGREEMENTS_ERROR";

export const AUTH_LOGOUT_REDIRECT = "AUTH_LOGOUT_REDIRECT";
