<template>
  <svg
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    focusable="false"
    :role="title ? 'img' : 'presentation'"
    :aria-labelledby="title ? id : false"
    xmlns="http://www.w3.org/2000/svg"
    :fill="color"
    :class="cssClass"
    :aria-hidden="title ? false : 'true'"
    :style="fontScale ? styleFontSize : false"
  >
    <title v-if="title" :id="id" v-html="title"></title>
    <g :transform="transform" v-html="icons[name]"></g>
  </svg>
</template>

<script>
import { iconMap } from "./icons.js";
export default {
  name: "RcIcon",
  props: {
    name: {
      type: String,
      default: "moreVertical"
    },
    title: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "currentColor"
    },
    scale: {
      type: String,
      default: "1"
    },
    fontScale: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      icons: iconMap,
      id: null
    };
  },
  computed: {
    cssClass() {
      return `rc-${this.name
        .replace(/([a-z0-9])([A-Z])/g, "$1-$2")
        .toLowerCase()} rc-icon-svg`;
    },
    transform() {
      return `translate(8 8) scale(${this.scale} ${this.scale}) translate(-8 -8)`;
    },
    styleFontSize() {
      return `font-size:${this.fontScale}%`;
    }
  },
  mounted() {
    this.id = "icon" + this._uid;
  }
};
</script>

<style lang="scss">
.rc-icon-svg {
  display: inline-block;
  overflow: visible;
}

//Bootstrap:  Make icons slightly larger in buttons, nav-links, dropdowns, and input-group-text
.btn,
.nav-link,
.dropdown-toggle,
.dropdown-item,
.input-group-text {
  .rc-icon-svg {
    font-size: 125%;
    vertical-align: text-bottom;
  }
}
</style>
