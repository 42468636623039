import axios from "@/utils/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    post: {
      data: {},
      status: "",
      errors: ""
    }
  };
}

const actions = {
  [constants.POST_RATING]: ({ commit, getters }, payload) => {
    return new Promise((resolve, reject) => {
      const team_uid = getters.getTeamUID;
      commit(constants.POST_RATING);
      axios
        .post(`teams/${team_uid}/ratings`, payload)
        .then((response) => {
          commit(constants.POST_RATING_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.POST_RATING_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.POST_RATING]: (state) => {
    state.post.status = "fetching";
    state.post.errors = {};
  },
  [constants.POST_RATING_SUCCESS]: (state, { data }) => {
    state.post.status = "success";
    state.post.errors = {};
    state.post.data = data;
  },
  [constants.POST_RATING_ERROR]: (state, err) => {
    state.post.status = "error";
    state.post.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  actions,
  mutations
};
