import Vue from "vue";
import Router from "vue-router";
import i18n from "#/locales";
import store from "#/stores";

// apps pages
import MarketRoutes from "./marketRoutes";
import AuthRoutes from "./authRoutes";
import FastChannelsRoutes from "./fastChannelsRoutes";
import TeamRoutes from "./teamRoutes";
import CatalogRoutes from "./catalogRoutes";
import FavoritesRoutes from "./favoritesRoutes";
import PlaylistsRoutes from "./playlistsRoutes";

import {
  AUTH_REFRESH,
  AUTH_GET_SESSION_TOKEN,
  AUTH_GET_IP_TOKEN
} from "@/libvue/apps/auth/actions";

import { redirectByRole } from "#/stores/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "homeNoLang",
      redirect: () => ({
        name: "home",
        params: { locale: i18n.locale }
      })
    },
    {
      path: "/:locale(en|fr)",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        ...AuthRoutes,
        ...MarketRoutes,
        ...FastChannelsRoutes,
        ...TeamRoutes,
        ...CatalogRoutes,
        ...FavoritesRoutes,
        ...PlaylistsRoutes
      ]
    },
    {
      path: "*",
      name: "404",
      redirect: () => ({
        name: "not.found",
        params: { locale: i18n.locale }
      })
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 });
        }
      }, 0);
    });
  }
});

// Use this to load data upon refresh or login
router.beforeEach((to, from, next) => {
  if (!to.meta?.layout) {
    to.meta.layout = "default";
  }
  // set locale from route param
  if (to.params.locale) {
    localStorage.setItem("pref-lang", to.params.locale);
    i18n.locale = to.params.locale;
  }

  // set document title from route param
  if (to.meta.title) {
    document.title = i18n.t(`${to.meta.title}`);
  } else {
    document.title = i18n.t("pageMeta.default.title");
  }

  let promise = "";
  // refresh token if it exists in local storage
  if (
    !store.getters.getProfile.user_uid &&
    localStorage.getItem("refresh-token")
  ) {
    promise = AUTH_REFRESH;
  }

  if (
    !from.name &&
    !store.getters.getProfile.user_uid &&
    !localStorage.getItem("refresh-token")
  ) {
    promise = AUTH_GET_SESSION_TOKEN;
  }

  promise
    ? store
        .dispatch(promise)
        .then((res) => {
          if (
            res.auth.role == "user" &&
            redirectByRole(res.auth).name !== to.name
          ) {
            const redirect = {
              ...redirectByRole(res.auth),
              params: { locale: i18n.locale },
              query: { redirect: to.fullPath }
            };
            next(redirect);
          }
          next();
        })
        .catch(() => {
          store
            .dispatch(AUTH_GET_IP_TOKEN)
            .then(() => next())
            .catch(() => next());
        })
    : next();
});

router.afterEach((to) => {
  /* 
  Translate current route url by getting the routes meta.alias if it exists.
  */

  if (i18n.locale == "fr" && to.meta.alias) {
    let result = [];
    const params = to.fullPath.split(to.path).pop();
    const pathSplit = to.path.split("/");

    to.meta.alias.forEach((term, index) => {
      if (term) {
        result.push(term);
      } else {
        result.push(pathSplit[index]);
      }
    });

    let resultPath = result.join("/");

    if (params) {
      resultPath = resultPath + params;
    }

    if (resultPath !== to.fullPath) {
      history.replaceState({}, null, resultPath);
    }
  }
});

export default router;
