import axios from "@/utils/axios.js";
import * as constants from "./actions";

function getDefaultState() {
  return {
    list: {
      data: { results: [] },
      status: "",
      errors: ""
    },
    detail: {
      data: {},
      status: "",
      errors: {}
    },
    posted: {
      status: "",
      errors: {}
    },
    deleted: {
      status: "",
      errors: {}
    }
  };
}

const getters = {
  getFavorite: (state) => (uid) => {
    return state.detail.data?.item?.uid == uid;
  }
};

const actions = {
  [constants.GET_FAVORITES]: ({ commit, rootState }) =>
    new Promise((resolve, reject) => {
      commit(constants.GET_FAVORITES);
      const user_uid = rootState.auth.user.user_uid;

      axios
        .get(`/users/${user_uid}/favorites`)
        .then((response) => {
          commit(constants.GET_FAVORITES_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_FAVORITES_ERROR, error);
          reject(error);
        });
    }),
  [constants.GET_FAVORITE]: ({ commit, rootState }, item_uid) =>
    new Promise((resolve, reject) => {
      commit(constants.GET_FAVORITE);
      const user_uid = rootState.auth.user.user_uid;

      axios
        .get(`/users/${user_uid}/favorites/${item_uid}`)
        .then((response) => {
          commit(constants.GET_FAVORITE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.GET_FAVORITE_ERROR, error);
          reject(error);
        });
    }),
  [constants.POST_FAVORITE]: ({ commit, rootState }, { uid }) =>
    new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      commit(constants.POST_FAVORITE);
      axios
        .post(`users/${user_uid}/favorites`, { item_id: uid })
        .then((response) => {
          commit(constants.POST_FAVORITE_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.POST_FAVORITE_ERROR, error);
          reject(error);
        });
    }),
  [constants.DELETE_FAVORITE]: ({ commit, dispatch, rootState }, { uid }) =>
    new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      commit(constants.DELETE_FAVORITE);

      axios
        .delete(`users/${user_uid}/favorites/${uid}`)
        .then(() => {
          commit(constants.DELETE_FAVORITE_SUCCESS, uid);
          dispatch(constants.GET_FAVORITES);
          resolve();
        })
        .catch((error) => {
          commit(constants.DELETE_FAVORITE_ERROR, error);
          reject(error);
        });
    }),
  [constants.DELETE_ALL_FAVORITES]: ({ commit, dispatch, rootState }) =>
    new Promise((resolve, reject) => {
      const user_uid = rootState.auth.user.user_uid;
      commit(constants.DELETE_ALL_FAVORITES);

      axios
        .delete(`users/${user_uid}/favorites/clear-all`)
        .then(() => {
          commit(constants.DELETE_ALL_FAVORITES_SUCCESS);
          dispatch(constants.GET_FAVORITES);
          resolve();
        })
        .catch((error) => {
          commit(constants.DELETE_ALL_FAVORITES_ERROR, error);
          reject(error);
        });
    }),
  [constants.FAVORITE_APPEND_NEXT]: ({ commit }, url) => {
    return new Promise((resolve, reject) => {
      commit(constants.FAVORITE_APPEND_NEXT);

      axios
        .get(url)
        .then((response) => {
          commit(constants.FAVORITE_APPEND_NEXT_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.FAVORITE_APPEND_NEXT_ERROR, error);
          reject(error);
        });
    });
  }
};

const mutations = {
  [constants.GET_FAVORITES]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
  },
  [constants.GET_FAVORITES_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data = data;
  },
  [constants.GET_FAVORITES_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = Object.assign({}, err.response.data);
  },
  [constants.GET_FAVORITE]: (state) => {
    state.detail.status = "fetching";
    state.detail.errors = {};
    state.detail.data = null;
  },
  [constants.GET_FAVORITE_SUCCESS]: (state, { data }) => {
    state.detail.status = "success";
    state.detail.errors = {};
    state.detail.data = data;
  },
  [constants.GET_FAVORITE_ERROR]: (state, err) => {
    state.detail.status = "error";
    state.detail.errors = Object.assign({}, err.response.data);
  },
  [constants.POST_FAVORITE]: (state) => {
    state.posted.status = "fetching";
    state.posted.errors = {};
  },
  [constants.POST_FAVORITE_SUCCESS]: (state, { data }) => {
    state.posted.status = "success";
    state.posted.errors = {};
    state.list.data.results.push(data);
    state.detail.data = data;
  },
  [constants.POST_FAVORITE_ERROR]: (state, err) => {
    state.posted.status = "error";
    state.posted.errors = Object.assign({}, err.response.data);
  },
  [constants.DELETE_FAVORITE]: (state) => {
    state.deleted.status = "remove";
    state.deleted.errors = {};
  },
  [constants.DELETE_FAVORITE_SUCCESS]: (state, uid) => {
    state.deleted.status = "success";
    state.deleted.errors = {};
    state.detail.data = null;
    state.list.data.results.splice(
      state.list.data.results.findIndex(
        (favorite) => favorite.item.uid === uid
      ),
      1
    );
  },
  [constants.DELETE_FAVORITE_ERROR]: (state, err) => {
    state.deleted.status = "error";
    state.deleted.errors = Object.assign({}, err.response.data);
  },
  [constants.DELETE_ALL_FAVORITES]: (state) => {
    state.deleted.status = "remove";
    state.deleted.errors = {};
  },
  [constants.DELETE_ALL_FAVORITES_SUCCESS]: (state) => {
    state.deleted.status = "success";
    state.deleted.errors = {};
  },
  [constants.DELETE_ALL_FAVORITES_ERROR]: (state, err) => {
    state.deleted.status = "error";
    state.deleted.errors = Object.assign({}, err.response.data);
  },
  [constants.FAVORITE_APPEND_NEXT]: (state) => {
    state.list.status = "fetching";
    state.list.errors = {};
  },
  [constants.FAVORITE_APPEND_NEXT_SUCCESS]: (state, { data }) => {
    state.list.status = "success";
    state.list.errors = {};
    state.list.data = {
      ...data,
      results: [...state.list.data.results, ...data.results]
    };
  },
  [constants.FAVORITE_APPEND_NEXT_ERROR]: (state, err) => {
    state.list.status = "error";
    state.list.errors = err;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
