import Vue from "vue";
import VueI18n from "vue-i18n";
import Config from "#/config";

const merge = require("deepmerge");

Vue.use(VueI18n);

function loadLocaleMessages() {
  const liblocales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9\s]+\.json$/i
  );
  const locales = require.context("#/locales", true, /[A-Za-z0-9\s]+\.json$/i);

  const libmessages = {};
  liblocales.keys().forEach((key) => {
    const libmatched = key.match(/([A-Za-z0-9]+)\./i);
    if (libmatched && libmatched.length > 1) {
      const liblocale = libmatched[1];
      libmessages[liblocale] = liblocales(key);
    }
  });

  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];

      messages[locale] = locales(key);
    }
  });

  const mergedMsgs = merge(libmessages, messages);

  return mergedMsgs;
}

function getInitialLang() {
  let initialLang = "en";
  const defaultLang = "en";

  let prefLang = localStorage.getItem("pref-lang");

  // swith old storage value
  if (prefLang == "en-US") {
    localStorage.setItem("pref-lang", "en");
    prefLang = "en";
  } else if (prefLang == "fr-CA") {
    localStorage.setItem("pref-lang", "fr");
    prefLang = "fr";
  }

  const navLang = navigator.language;

  if (Config.i18n()) {
    if (prefLang) {
      initialLang = prefLang;
    } else {
      initialLang = navLang.includes("fr") ? "fr" : defaultLang;
    }
  }

  return initialLang;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || getInitialLang(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages()
});
