export const UPLOADS_ASSET_SET_SELECTED = "UPLOADS_ASSET_SET_SELECTED";
export const UPLOADS_ASSET_SET_VIEW_COMPONENT =
  "UPLOADS_ASSET_SET_VIEW_COMPONENT";

export const UPLOADS_SEND_TO_REGULATOR = "UPLOADS_SEND_TO_REGULATOR";
export const UPLOADS_SEND_TO_REGULATOR_SUCCESS =
  "UPLOADS_SEND_TO_REGULATOR_SUCCESS";
export const UPLOADS_SEND_TO_REGULATOR_ERROR =
  "UPLOADS_SEND_TO_REGULATOR_ERROR";

export const UPLOADS_APPEND_NEXT = "UPLOADS_APPEND_NEXT";
export const UPLOADS_APPEND_NEXT_SUCCESS = "UPLOADS_APPEND_NEXT_SUCCESS";
export const UPLOADS_APPEND_NEXT_ERROR = "UPLOADS_APPEND_NEXT_ERROR";

export const UPLOADS_GET_S3_CONFIG = "UPLOADS_GET_S3_CONFIG";
export const UPLOADS_GET_S3_CONFIG_SUCCESS = "UPLOADS_GET_S3_CONFIG_SUCCESS";
export const UPLOADS_GET_S3_CONFIG_ERROR = "UPLOADS_GET_S3_CONFIG_ERROR";

export const UPLOADS_GET_ASSETS = "UPLOADS_GET_ASSETS";
export const UPLOADS_GET_ASSETS_SUCCESS = "UPLOADS_GET_ASSETS_SUCCESS";
export const UPLOADS_GET_ASSETS_ERROR = "UPLOADS_GET_ASSETS_ERROR";

export const UPLOADS_GET_S3_SIGNATURE = "UPLOADS_GET_S3_SIGNATURE";
export const UPLOADS_GET_S3_SIGNATURE_SUCCESS =
  "UPLOADS_GET_S3_SIGNATURE_SUCCESS";
export const UPLOADS_GET_S3_SIGNATURE_ERROR = "UPLOADS_GET_S3_SIGNATURE_ERROR";

export const UPLOADS_SET_PROGRESS = "UPLOADS_SET_PROGRESS";
