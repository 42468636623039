<template>
  <div id="rcMainWrapper" class="rc-wrapper" tabindex="-1">
    <rc-notifications />
    <rc-alert-cookie />
    <rc-skip-nav />
    <rc-site-header />
    <rc-site-content />
    <rc-site-footer />
  </div>
</template>

<script>
import SkipNav from "@/libvue/components/skipnav/SkipNav.vue";
import AlertCookie from "@/libvue/components/alert/Cookie.vue";
import SiteContent from "#/layout/components/SiteContent.vue";
import SiteFooter from "#/layout/components/SiteFooter.vue";
import SiteHeader from "#/layout/components/SiteHeader.vue";

export default {
  name: "DefaultLayout",

  components: {
    "rc-skip-nav": SkipNav,
    "rc-alert-cookie": AlertCookie,
    "rc-site-content": SiteContent,
    "rc-site-footer": SiteFooter,
    "rc-site-header": SiteHeader
  }
};
</script>
