<template>
  <div
    v-if="isVisible"
    class="rc-notification alert alert-dismissible shadow"
    role="alert"
    aria-live="polite"
    aria-atomic="true"
  >
    <div class="row no-gutters align-items-center">
      <div :class="`col-auto text-${item.type}`">
        <RcIcon :name="item.type" fontScale="270"></RcIcon>
      </div>
      <div class="col-9 pl-2">
        <div class="rc-notification-msg" v-html="item.message"></div>
      </div>
    </div>

    <button class="btn rc-btn-icon rc-btn-close" @click="close()">
      <span class="sr-only">{{ $t("common.close") }}</span>
      <RcIcon name="closeBold" scale=".65" :title="$t(`common.close`)"></RcIcon>
    </button>
  </div>
</template>

<script>
import RcIcon from "@/libvuePure/components/icons/Base.vue";
export default {
  components: {
    RcIcon
  },
  props: {
    item: {
      type: Object,
      default: undefined
    }
  },
  data() {
    return {
      isVisible: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.close();
    }, 10000);
  },

  methods: {
    close() {
      this.isVisible = false;
      this.$emit("close", this.item);
      this.$destroy();
    }
  }
};
</script>
<style lang="scss">
.rc-notification {
  background-color: var(--white);

  &-msg {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.2;
    color: var(--black);
  }
  .rc-btn-close {
    position: absolute;
    top: 2px;
    right: 2px;
    color: var(--black);
    background-color: var(--white);
    &:hover {
      color: var(--gray);
    }
  }
}
</style>
