export const TEAM_ARCHIVES_SEARCH_ASSETS = "TEAM_ARCHIVES_SEARCH_ASSETS";
export const TEAM_ARCHIVES_SEARCH_ASSETS_SUCCESS =
  "TEAM_ARCHIVES_SEARCH_ASSETS_SUCCESS";
export const TEAM_ARCHIVES_SEARCH_ASSETS_ERROR =
  "TEAM_ARCHIVES_SEARCH_ASSETS_ERROR";

export const TEAM_ARCHIVES_REQUEST = "TEAM_ARCHIVES_REQUEST";
export const TEAM_ARCHIVES_REQUEST_SUCCESS = "TEAM_ARCHIVES_REQUEST_SUCCESS";
export const TEAM_ARCHIVES_REQUEST_ERROR = "TEAM_ARCHIVES_REQUEST_ERROR";

export const ARCHIVES_ASSET_SET_SELECTED = "ARCHIVES_ASSET_SET_SELECTED";
export const ARCHIVES_ASSET_SET_VIEW_COMPONENT =
  "ARCHIVES_ASSET_SET_VIEW_COMPONENT";

export const ARCHIVES_SEARCH_SET_ORDER_BY = "ARCHIVES_SEARCH_SET_ORDER_BY";
export const TEAM_ARCHIVES_AGGS_REQUEST = "TEAM_ARCHIVES_AGGS_REQUEST";
export const TEAM_ARCHIVES_AGGS_REQUEST_SUCCESS =
  "TEAM_ARCHIVES_AGGS_REQUEST_SUCCESS";
export const TEAM_ARCHIVES_AGGS_REQUEST_ERROR =
  "TEAM_ARCHIVES_AGGS_REQUEST_ERROR";

export const TEAM_ARCHIVES_SEND_TO_REGULATOR =
  "TEAM_ARCHIVES_SEND_TO_REGULATOR";
export const TEAM_ARCHIVES_SEND_TO_REGULATOR_SUCCESS =
  "TEAM_ARCHIVES_SEND_TO_REGULATOR_SUCCESS";
export const TEAM_ARCHIVES_SEND_TO_REGULATOR_ERROR =
  "TEAM_ARCHIVES_SEND_TO_REGULATOR_ERROR";

export const TEAM_ARCHIVES_APPEND_NEXT = "TEAM_ARCHIVES_APPEND_NEXT";
export const TEAM_ARCHIVES_APPEND_NEXT_SUCCESS =
  "TEAM_ARCHIVES_APPEND_NEXT_SUCCESS";
export const TEAM_ARCHIVES_APPEND_NEXT_ERROR =
  "TEAM_ARCHIVES_APPEND_NEXT_ERROR";
