import Config from "#/config";
import i18n from "#/locales";
import axios from "@/utils/axios.js";

import * as constants from "./actions";
import routes from "#/routes";

// TODO: remove cross storee imports
import { ROOT_RESET_ALL_STATES } from "#/stores/root";
import moment from "moment";

function getDefaultState() {
  return {
    errors: {},
    hasLoadedOnce: false,
    pin: {
      key: null
    },
    ipToken: {
      data: { auth: {} },
      status: null,
      errors: null
    },
    verify: {
      data: { auth: {} },
      status: null,
      errors: null
    },
    request: {
      object: null,
      user: null,
      organisation: null,
      step: null,
      teamType: null
    },
    status: "",
    token: localStorage.getItem("refresh-token") || "",
    user: {
      agreement_version: "",
      domain_approved: "",
      domain_name: "",
      email: "",
      full_name: "",
      last_login: "",
      role: "",
      scopes: [],
      user_uid: "",
      categories: [],
      age_groups: [],
      curricula: []
    },
    domain: {
      data: null,
      status: "",
      error: null
    }
  };
}

const getters = {
  getProfile(state) {
    return state.user;
  },
  getBaseUrl: (state) => state.user.base_url,
  isAuthenticated: (state) => !!state.token,
  getPinKey: (state) => state.pin.key,
  getTeamUID: (state) => state.user.team_uid,
  getUserUID: (state) => state.user.user_uid,
  roleIsTeacher: (state) => state.user.role.includes("teacher"),
  roleIsStudent: (state) => state.user.role.includes("student"),
  roleIsGuest: (state) => state.user.role.includes("guest"),
  roleIsAdmin: (state) => state.user.role?.includes("admin"),
  roleIsLibraryUser: (state) => state.user.role == "library_user",
  orgIsPublic: (state) =>
    ["public_library", "other", "museum"].includes(
      state.user.organisation_type
    ),
  hasAcceptedAgreements: (state) =>
    state.user.agreement_version &&
    state.user.agreement_version == Config.agreement.version,
  profileCurricula: (state) =>
    state.user.curricula.filter((c) => c.languages.includes(i18n.locale)),
  profileCategories: (state) =>
    state.user.categories.map((c) => (c.uid ? c.uid : c))
};

const actions = {
  [constants.AUTH_REQUEST]: ({ commit }, user) =>
    new Promise((resolve, reject) => {
      commit(constants.AUTH_REQUEST);
      axios({
        method: "post",
        url: "auth/token",
        data: user,
        authRequired: false
      })
        .then(({ data }) => {
          localStorage.setItem("user-token", data.token);
          commit(constants.AUTH_SUCCESS, data);
          resolve(data);
        })
        .catch((error) => {
          commit(constants.AUTH_ERROR, error);
          localStorage.removeItem("refresh-token");
          reject(error);
        });
    }),
  [constants.AUTH_LOGOUT]: ({ commit, dispatch }, redirect) =>
    new Promise((resolve) => {
      commit(constants.AUTH_LOGOUT);
      localStorage.removeItem("refresh-token");
      localStorage.removeItem("user-token");
      localStorage.removeItem("local-default-membership-uid");
      localStorage.removeItem("authorized");
      localStorage.removeItem("auth-pin");

      axios({ url: `auth/session-logout`, authRequired: false }).then(() => {
        dispatch(constants.AUTH_GET_IP_TOKEN)
          .then(() => {
            dispatch(constants.AUTH_LOGOUT_REDIRECT, redirect).then(() => {
              resolve();
            });
          })
          .catch(() => {
            dispatch(constants.AUTH_LOGOUT_REDIRECT, redirect).then(() => {
              dispatch(ROOT_RESET_ALL_STATES);
              resolve();
            });
          });
      });
    }),
  [constants.AUTH_LOGOUT_REDIRECT]: (_, redirect) => {
    if (routes.currentRoute.name !== Config.auth.redirects.logout.name) {
      const to = redirect
        ? redirect
        : {
            ...Config.auth.redirects.logout,
            params: { locale: i18n.locale }
          };
      routes.push(to);
    }
  },
  [constants.AUTH_REFRESH]: ({ commit, dispatch }, membership) =>
    new Promise((resolve, reject) => {
      commit(constants.AUTH_REFRESH);

      let switch_to_team = null;

      if (membership && membership.team.uid) {
        switch_to_team = membership.team.uid;
      } else if (localStorage.getItem("local-default-membership-uid")) {
        switch_to_team = localStorage.getItem("local-default-membership-uid");
      }

      const args = switch_to_team
        ? { refresh: localStorage.getItem("refresh-token"), switch_to_team }
        : { refresh: localStorage.getItem("refresh-token") };

      const verifyConfig = {
        method: "post",
        url: "auth/verify",
        baseURL: Config.apiBase(),
        data: { token: localStorage.getItem("refresh-token") },
        authRequired: false
      };

      const refreshConfig = {
        method: "post",
        url: "auth/refresh",
        baseURL: Config.apiBase(),
        data: args,
        authRequired: false
      };

      axios(verifyConfig)
        .then(() => {
          axios(refreshConfig)
            .then(({ data }) => {
              localStorage.setItem("user-token", data.token);
              commit(constants.AUTH_SUCCESS, data);
              resolve(data);
            })
            .catch((err) => {
              commit(constants.AUTH_ERROR, err);
              dispatch(constants.AUTH_LOGOUT);
              reject(err);
            });
        })
        .catch((err) => {
          dispatch(constants.AUTH_LOGOUT);
          reject(err);
        });
    }),
  [constants.AUTH_VERIFY]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.AUTH_VERIFY);

      axios({
        method: "post",
        url: "auth/verify",
        baseURL: Config.apiBase(),
        data: { token: localStorage.getItem("user-token") },
        authRequired: false
      })
        .then((response) => {
          commit(constants.AUTH_SUCCESS, response.data);
          commit(constants.AUTH_VERIFY_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.AUTH_VERIFY_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.AUTH_REQUEST_PIN]: ({ state, commit }, data) =>
    new Promise((resolve, reject) => {
      commit(constants.AUTH_REQUEST_PIN);

      const requestConfig = {
        method: "post",
        url: "auth/send-pin",
        baseURL: Config.apiBase(),
        data,
        authRequired:
          state.ipToken.data.auth.remote_key_access ||
          state.verify.data.auth.remote_key_access
      };

      axios(requestConfig)
        .then((response) => {
          commit(constants.AUTH_REQUEST_PIN_SUCCESS, response);
          resolve(response);
        })
        .catch((error) => {
          commit(constants.AUTH_REQUEST_PIN_ERROR, error);
          reject(error);
        });
    }),
  [constants.AUTH_REQUEST_TOKEN]: ({ commit, state, dispatch }, params) =>
    new Promise((resolve, reject) => {
      commit(constants.AUTH_REQUEST_TOKEN);

      axios({
        method: "post",
        url: "auth/pin-token",
        data: params,
        authRequired:
          state.ipToken.data.auth.remote_key_access ||
          state.verify.data.auth.remote_key_access
      })
        .then(({ data }) => {
          commit(constants.AUTH_REQUEST_TOKEN_SUCCESS, data);

          localStorage.setItem("refresh-token", data.refresh);
          localStorage.setItem("user-token", data.token);

          if (Config.agreement.version) {
            dispatch(constants.AUTH_SET_AGREEMENTS, {
              agreement_version: Config.agreement.version
            }).then(() => {
              resolve(data);
            });
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          commit(constants.AUTH_REQUEST_TOKEN_ERROR, error);
          reject(error);
        });
    }),
  [constants.AUTH_GET_IP_TOKEN]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit(constants.AUTH_GET_IP_TOKEN);
      commit(constants.AUTH_REQUEST_TOKEN);
      axios({ url: "auth/ip-token", authRequired: false })
        .then(({ data }) => {
          commit(constants.AUTH_GET_IP_TOKEN_SUCCESS, data);
          commit(constants.AUTH_REQUEST_TOKEN_SUCCESS, data);
          commit(constants.AUTH_SUCCESS, data);

          localStorage.setItem("user-token", data.token);
          resolve(data);
        })
        .catch((error) => {
          commit(constants.AUTH_GET_IP_TOKEN_ERROR, error);
          commit(constants.AUTH_REQUEST_TOKEN_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.AUTH_GET_SESSION_TOKEN]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem("refresh-token");
      commit(constants.AUTH_REQUEST_TOKEN);

      const requestConfig = {
        url: "auth/session-token",
        baseURL: Config.apiBase(),
        authRequired: false
      };

      axios(requestConfig)
        .then(({ data }) => {
          commit(constants.AUTH_REQUEST_TOKEN_SUCCESS, data);
          commit(constants.AUTH_SUCCESS, data);

          localStorage.setItem("refresh-token", data.refresh);
          localStorage.setItem("user-token", data.token);

          if (Config.agreement.version) {
            dispatch(constants.AUTH_SET_AGREEMENTS, {
              agreement_version: Config.agreement.version
            }).then(() => {
              resolve(data);
            });
          } else {
            resolve(data);
          }
        })
        .catch((error) => {
          commit(constants.AUTH_REQUEST_TOKEN_ERROR, error);
          reject(error);
        });
    });
  },
  [constants.AUTH_SET_AGREEMENTS]: ({ commit, state }, data) =>
    new Promise((resolve, reject) => {
      const user_uid = state.user.user_uid;
      commit(constants.AUTH_SET_AGREEMENTS);
      axios
        .patch(`users/${user_uid}/set-agreement`, data)
        .then((response) => {
          commit(constants.AUTH_SET_AGREEMENTS_SUCCESS, response);
          commit(constants.AUTH_SET_USER, response.data);

          resolve(response);
        })
        .catch((error) => {
          commit(constants.AUTH_SET_AGREEMENTS_ERROR, error);
          reject(error);
        });
    })
};

const mutations = {
  [constants.AUTH_REQUEST]: (state) => {
    state.status = "logging";
    state.errors = {};
  },
  [constants.AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.token;
    state.user = resp.auth;
    state.errors = {};
  },
  [constants.AUTH_ERROR]: (state, err) => {
    state.status = "error";
    state.hasLoadedOnce = true;
    state.errors = err.response ? err.response.data : err;
  },
  [constants.AUTH_LOGOUT]: (state) => {
    state.token = "";
    state.errors = {};
  },
  [constants.AUTH_REFRESH]: (state) => {
    state.status = "refreshing";
  },
  [constants.AUTH_GET_IP_TOKEN]: (state) => {
    state.ipToken.status = "fetching";
    state.ipToken.errors = {};
  },
  [constants.AUTH_GET_IP_TOKEN_SUCCESS]: (state, data) => {
    state.ipToken.status = "success";
    state.ipToken.errors = {};
    state.ipToken.data = data;
  },
  [constants.AUTH_GET_IP_TOKEN_ERROR]: (state, err) => {
    state.ipToken.status = "error";
    state.ipToken.errors = err;
  },
  [constants.AUTH_VERIFY]: (state) => {
    state.verify.status = "fetching";
    state.verify.errors = {};
  },
  [constants.AUTH_VERIFY_SUCCESS]: (state, { data }) => {
    state.verify.status = "success";
    state.verify.errors = {};
    state.verify.data = data;
    state.token = localStorage.getItem("user-token");
  },
  [constants.AUTH_VERIFY_ERROR]: (state, err) => {
    state.verify.status = "error";
    state.verify.errors = err;
  },
  [constants.AUTH_REQUEST_CREATE_ORGANISATION]: (
    state,
    { response: { data }, data: { team_type, user_name } }
  ) => {
    state.request.organisation = data;
    state.request.teamType = team_type;
    state.request.user = {
      ...state.request.user,
      full_name: user_name
    };
    sessionStorage.setItem("request", JSON.stringify(state.request));
  },
  [constants.AUTH_RESET]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  },
  [constants.AUTH_REQUEST_PIN]: (state) => {
    state.status = "sending_pin";
    state.errors = {};
    localStorage.removeItem("auth-pin");
  },
  [constants.AUTH_REQUEST_PIN_SUCCESS]: (state, response) => {
    state.status = "success";
    state.errors = {};
    state.pin.key = response.data.key;

    const json = JSON.stringify({
      key: response.data.key,
      exp: moment().add(10, "minutes")
    });
    localStorage.setItem("auth-pin", json);
  },
  [constants.AUTH_REQUEST_PIN_ERROR]: (state, err) => {
    state.status = "error";
    state.errors = err.response ? err.response.data : err;
  },
  [constants.AUTH_REQUEST_TOKEN]: (state) => {
    state.status = "requesting_pin";
    state.errors = {};
  },
  [constants.AUTH_REQUEST_TOKEN_SUCCESS]: (state, data) => {
    state.status = "success";
    state.errors = {};
    state.token = data.token;
    state.user = data.auth;
  },
  [constants.AUTH_REQUEST_TOKEN_ERROR]: (state, err) => {
    state.status = "error";
    state.errors = err.response ? err.response.data : err;
  },
  [constants.AUTH_SET_USER]: (state, data) => {
    state.user = {
      ...state.user,
      ...data
    };
  },
  [constants.AUTH_SET_AGREEMENTS]: (state) => {
    state.status = "loading";
    state.errors = {};
  },
  [constants.AUTH_SET_AGREEMENTS_SUCCESS]: (state) => {
    state.status = "success";
    state.errors = {};
  },
  [constants.AUTH_SET_AGREEMENTS_ERROR]: (state, error) => {
    state.status = "error";
    state.errors = error;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState(),
  getters,
  actions,
  mutations
};
