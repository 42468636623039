export const GET_FAST_CHANNELS = "GET_FAST_CHANNELS";
export const GET_FAST_CHANNELS_SUCCESS = "GET_FAST_CHANNELS_SUCCESS";
export const GET_FAST_CHANNELS_ERROR = "GET_FAST_CHANNELS_ERROR";

export const GET_FAST_CHANNEL = "GET_FAST_CHANNEL";
export const GET_FAST_CHANNEL_SUCCESS = "GET_FAST_CHANNEL_SUCCESS";
export const GET_FAST_CHANNEL_ERROR = "GET_FAST_CHANNEL_ERROR";

export const GET_FAST_CHANNEL_DAY = "GET_FAST_CHANNEL_DAY";
export const GET_FAST_CHANNEL_DAY_SUCCESS = "GET_FAST_CHANNEL_DAY_SUCCESS";
export const GET_FAST_CHANNEL_DAY_ERROR = "GET_FAST_CHANNEL_DAY_ERROR";

export const GET_FAST_CHANNEL_RANGE = "GET_FAST_CHANNEL_RANGE";
export const GET_FAST_CHANNEL_RANGE_SUCCESS = "GET_FAST_CHANNEL_RANGE_SUCCESS";
export const GET_FAST_CHANNEL_RANGE_ERROR = "GET_FAST_CHANNEL_RANGE_ERROR";

export const GET_FAST_CHANNEL_WEEK = "GET_FAST_CHANNEL_WEEK";
export const GET_FAST_CHANNEL_WEEK_SUCCESS = "GET_FAST_CHANNEL_WEEK_SUCCESS";
export const GET_FAST_CHANNEL_WEEK_ERROR = "GET_FAST_CHANNEL_WEEK_ERROR";

export const GET_STREAM = "GET_STREAM";
export const GET_STREAM_SUCCESS = "GET_STREAM_SUCCESS";
export const GET_STREAM_ERROR = "GET_STREAM_ERROR";

export const PATCH_STREAM = "PATCH_STREAM";
export const PATCH_STREAM_SUCCESS = "PATCH_STREAM_SUCCESS";
export const PATCH_STREAM_ERROR = "PATCH_STREAM_ERROR";

export const POST_STREAM = "POST_STREAM";
export const POST_STREAM_SUCCESS = "POST_STREAM_SUCCESS";
export const POST_STREAM_ERROR = "POST_STREAM_ERROR";

export const GET_STREAMS = "GET_STREAMS";
export const GET_STREAMS_SUCCESS = "GET_STREAMS_SUCCESS";
export const GET_STREAMS_ERROR = "GET_STREAMS_ERROR";

export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

export const POST_EVENT = "POST_EVENT";
export const POST_EVENT_SUCCESS = "POST_EVENT_SUCCESS";
export const POST_EVENT_ERROR = "POST_EVENT_ERROR";

export const POST_EVENT_BUILD = "POST_EVENT_BUILD";
export const POST_EVENT_BUILD_SUCCESS = "POST_EVENT_BUILD_SUCCESS";
export const POST_EVENT_BUILD_ERROR = "POST_EVENT_BUILD_ERROR";

export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_ERROR = "DELETE_EVENT_ERROR";

export const PATCH_EVENT = "PATCH_EVENT";
export const PATCH_EVENT_SUCCESS = "PATCH_EVENT_SUCCESS";
export const PATCH_EVENT_ERROR = "PATCH_EVENT_ERROR";

export const GET_FREQUENCY_RULES = "GET_FREQUENCY_RULES";
export const GET_FREQUENCY_RULES_SUCCESS = "GET_FREQUENCY_RULES_SUCCESS";
export const GET_FREQUENCY_RULES_ERROR = "GET_FREQUENCY_RULES_ERROR";

export const SET_OCCURRENCE_DETAIL = "SET_OCCURRENCE_DETAIL";
export const UPDATE_OCCURRENCE = "UPDATE_OCCURENCE";

export const POST_OCCURRENCE = "POST_OCCURRENCE";
export const POST_OCCURRENCE_SUCCESS = "POST_OCCURRENCE_SUCCESS";
export const POST_OCCURRENCE_ERROR = "POST_OCCURRENCE_ERROR";

export const PATCH_OCCURRENCE = "PATCH_OCCURRENCE";
export const PATCH_OCCURRENCE_SUCCESS = "PATCH_OCCURRENCE_SUCCESS";
export const PATCH_OCCURRENCE_ERROR = "PATCH_OCCURRENCE_ERROR";

export const DELETE_OCCURRENCE = "DELETE_OCCURRENCE";
export const DELETE_OCCURRENCE_SUCCESS = "DELETE_OCCURRENCE_SUCCESS";
export const DELETE_OCCURRENCE_ERROR = "DELETE_OCCURRENCE_ERROR";

export const DELETE_EVENT_OCCS = "DELETE_EVENT_OCCS";
export const DELETE_EVENT_OCCS_SUCCESS = "DELETE_EVENT_OCCS_SUCCESS";
export const DELETE_EVENT_OCCS_ERROR = "DELETE_EVENT_OCCS_ERROR";

export const SYNC_SCHEDULE = "SYNC_SCHEDULE";
