import app from "#/app";
import store from "#/stores";
import Config from "#/config";
import i18n from "#/locales";

export function ifNotAuthenticated(to, from, next) {
  if (!store.getters.isAuthenticated || !store.getters.hasAcceptedAgreements) {
    next();
    return;
  }
  const redirect = {
    name: "home",
    params: { locale: i18n.locale }
  };
  next(redirect);
}

export function ifAuthenticated(to, from, next) {
  if (store.getters.isAuthenticated) {
    return next();
  }
  next({
    name: "auth.login",
    params: { locale: i18n.locale },
    query: { redirect: to.fullPath }
  });
}

export function ifUserKnown(to, from, next) {
  if (store.getters.isAuthenticated) {
    const redirect = {
      ...redirectByRole(store.state.auth.user),
      params: { locale: i18n.locale }
    };
    app.$Progress.finish();
    next(redirect);
  } else {
    next({
      name: "auth.login",
      params: { locale: i18n.locale }
    });
  }
}

export function redirectByRole(auth) {
  const redirect = Config.auth.redirects.afterLoginSuccess[auth.role]
    ? Config.auth.redirects.afterLoginSuccess[auth.role]
    : Config.auth.redirects.afterLoginSuccess["default"];

  return redirect;
}

export function ifUserIsAdmin(to, from, next) {
  if (store.getters.isAuthenticated && store.getters.roleIsAdmin) {
    return next();
  }
  next({
    name: "403",
    params: { locale: i18n.locale }
  });
}

export function ifUserInTeam(to, from, next) {
  if (store.state.auth.user.team_uid && !store.getters.roleIsGuest) {
    next();
  } else {
    next({ name: "user.account.team.join", params: { locale: i18n.locale } });
  }
}
