<template>
  <div class="rc-loader" :class="{ 'loading-done': isPageLoaded }">
    <div id="rcAnnouncer" class="sr-only" aria-live="polite">
      <span class="rc-text-loaded">{{ $t("a11y.status.page_loaded") }}</span>
      <span class="rc-text-loading">{{ $t("a11y.status.page_loading") }}</span>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "RcPageLoader",
  computed: {
    ...mapState({
      isPageLoaded: (state) => state.root.isPageLoaded
    })
  }
};
</script>


<style lang="scss" scoped>
.rc-loader {
  .rc-text-loaded {
    display: none;
  }
  &.loading-done {
    .rc-text-loading {
      display: none;
    }
    .rc-text-loaded {
      display: block;
    }
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
</style>
