import axios from "axios";
import Config from "#/config";
import i18n from "#/locales";
import { version } from "vue";

const vueVersion3 = version[0] == "3";
const instance = axios.create({
  baseURL: Config.apiBase(),
  authRequired: true
});

instance.interceptors.request.use(
  (config) => {
    const token = config?.data?.refresh
      ? localStorage.getItem("refresh-token")
      : localStorage.getItem("user-token");

    if (token && config.authRequired) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers["Content-Language"] = vueVersion3
      ? i18n.global.locale.value
      : i18n.locale;
    config.headers["Accept-Language"] = vueVersion3
      ? i18n.global.locale.value
      : i18n.locale;
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
