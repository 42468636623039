<template>
  <component
    :is="cmpIs"
    :to="to.name ? to : null"
    :href="href ? href : null"
    :disabled="disabled ? disabled : null"
    :class="btnClasses"
    @click="handleClick"
  >
    <slot></slot>
  </component>
</template>

<script>
import { version } from "vue";
import { RouterLink } from "vue-router";
export default {
  name: "RcBtn",
  components: {
    RouterLink
  },
  props: {
    // When set to `true`, places the component in the active state with active styling
    active: {
      type: Boolean,
      default: false
    },
    // <router-link> prop: Configure the active CSS class applied when the link is active. Typically you will want to set this to class name 'active'
    activeClass: {
      type: String,
      default: "active"
    },
    // Renders a 100% width button (expands to the width of its parent container)
    block: {
      type: Boolean,
      default: false
    },
    // Applies one of the Bootstrap theme color variants to the component
    variant: {
      type: String,
      default: "secondary"
    },
    // <router-link> prop: Denotes the target route of the link. When clicked,
    // the value of the to prop will be passed to `router.push()` internally,
    // so the value can be either a string or a Location descriptor object
    to: {
      type: Object,
      default: () => ({})
    },
    // Renders the button with the pill style appearance when set to 'true'
    pill: {
      type: Boolean,
      default: false
    },
    // Set the size of the component's appearance. 'sm', 'md' (default), or 'lg'
    size: {
      type: String,
      default: "md"
    },
    href: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    btnClasses() {
      return [
        "btn",
        `btn-${this.variant}`,
        `btn-${this.size}`,
        this.active ? "active" : "",
        this.block ? "w-100 d-block" : "",
        this.disabled ? "disabled" : "",
        this.pill ? "rounded-pill" : ""
      ];
    },
    cmpIs() {
      let cmp = "button";
      if (this.to.name) {
        cmp = "router-link";
      } else if (this.href) {
        cmp = "a";
      }
      return cmp;
    }
  },
  methods: {
    handleClick(e) {
      //vuejs 3 does not need to emit native event
      version[0] == "3" ? null : this.$emit("click", e);
    }
  }
};
</script>
