export const GET_PLAYLISTS = "GET_PLAYLISTS";
export const GET_PLAYLISTS_SUCCESS = "GET_PLAYLISTS_SUCCESS";
export const GET_PLAYLISTS_ERROR = "GET_PLAYLISTS_ERROR";

export const POST_PLAYLIST = "POST_PLAYLIST";
export const POST_PLAYLIST_SUCCESS = "POST_PLAYLIST_SUCCESS";
export const POST_PLAYLIST_ERROR = "POST_PLAYLIST_ERROR";

export const GET_PLAYLIST = "GET_PLAYLIST";
export const GET_PLAYLIST_SUCCESS = "GET_PLAYLIST_SUCCESS";
export const GET_PLAYLIST_ERROR = "GET_PLAYLIST_ERROR";

export const PATCH_PLAYLIST = "PATCH_PLAYLIST";
export const PATCH_PLAYLIST_SUCCESS = "PATCH_PLAYLIST_SUCCESS";
export const PATCH_PLAYLIST_ERROR = "PATCH_PLAYLIST_ERROR";

export const DELETE_PLAYLIST = "DELETE_PLAYLIST";
export const DELETE_PLAYLIST_SUCCESS = "DELETE_PLAYLIST_SUCCESS";
export const DELETE_PLAYLIST_ERROR = "DELETE_PLAYLIST_ERROR";

export const PATCH_ADD_SCREENERS = "PATCH_ADD_SCREENERS";
export const PATCH_ADD_SCREENERS_SUCCESS = "PATCH_ADD_SCREENERS_SUCCESS";
export const PATCH_ADD_SCREENERS_ERROR = "PATCH_ADD_SCREENERS_ERROR";

export const DELETE_SEGMENT = "DELETE_SEGMENT";
export const DELETE_SEGMENT_SUCCESS = "DELETE_SEGMENT_SUCCESS";
export const DELETE_SEGMENT_ERROR = "DELETE_SEGMENT_ERROR";

export const OPEN_PLAYLIST_MODAL = "OPEN_PLAYLIST_MODAL";
export const OPEN_PLAYLIST_MODAL_SUCCESS = "OPEN_PLAYLIST_MODAL_SUCCESS";
export const OPEN_PLAYLIST_MODAL_ERROR = "OPEN_PLAYLIST_MODAL_ERROR";

export const SET_MODAL_MODE = "SET_MODAL_MODE";

export const REORDER_PLAYLIST = "REORDER_PLAYLIST";
export const REORDER_PLAYLIST_SUCCESS = "REORDER_PLAYLIST_SUCCESS";
export const REORDER_PLAYLIST_ERROR = "REORDER_PLAYLIST_ERROR";

export const SET_SEGMENT_TO_EDIT = "SET_SEGMENT_TO_EDIT";

export const PATCH_SEGMENT = "PATCH_SEGMENT";
export const PATCH_SEGMENT_SUCCESS = "PATCH_SEGMENT_SUCCESS";
export const PATCH_SEGMENT_ERROR = "PATCH_SEGMENT_ERROR";
