import {
  getUserInfo,
  getPageInfo,
  getTeamInfo
} from "@/plugins/statistics/baseStats";
import Config from "#/config";
import i18n from "#/locales";

import { Murdock } from "@cbcrc/murdock";

const murdockService = new Murdock({
  host: Config.apiHost() ? Config.apiHost() : window.location.hostname,
  basePath: "api/v2/stats",
  protocol: Config.API().use_ssl ? "https" : "http",
  requestType: "beaconAPI"
});
const rcPageStats = {
  install(Vue, options) {
    const router = options.router;
    const state = options.stores.state;

    murdockService.initAutoTracking();
    murdockService.on("recordEvents", function (record) {
      if (window.dataLayerQueue !== undefined) {
        record.events.forEach((e) => {
          if (e.event_type == "pageview") {
            window.dataLayerQueue.push({
              ...e,
              event: "pageView",
              app: {
                name: Config.decodedHost
              },
              page: {
                ...e.page,
                language: i18n.locale,
                age_group_uid: state.auth.user.team_age_groups || ""
              }
            });
          }
        });
      }
    });

    router.afterEach((to) => {
      setTimeout(() => {
        const payload = formatPayload(to, state);
        postPageStats(to, payload);
      }, 3000);
    });
  }
};
const postPageStats = (to, payload) => {
  //Extend User & Team infos to ALl Murdock events
  murdockService.extendEvents(function () {
    return {
      user: payload.user_info ? payload.user_info : [],
      team: payload.team_info ? payload.team_info : []
    };
  });
  //Send pageviews event
  murdockService.deferEvent(payload.event_type, payload);
};
const formatPayload = (to, state) => {
  const info = {};
  info.event_type = "pageview";
  if ("user_uid" in state.auth.user && state.auth.user.user_uid)
    info.user_info = getUserInfo(state.auth.user);
  info.page_info = getPageInfo(to);
  if (Config.team.enabled) {
    info.team_info = getTeamInfo(state.auth.user);
  }

  return info;
};

export default rcPageStats;
