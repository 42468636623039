export const POST_IMPORT_ITEMS = "POST_IMPORT_ITEMS";
export const POST_IMPORT_ITEMS_SUCCESS = "POST_IMPORT_ITEMS_SUCCESS";
export const POST_IMPORT_ITEMS_ERROR = "POST_IMPORT_ITEMS_ERROR";

export const GET_IMPORTERS = "GET_IMPORTERS";
export const GET_IMPORTERS_SUCCESS = "GET_IMPORTERS_SUCCESS";
export const GET_IMPORTERS_ERROR = "GET_IMPORTERS_ERROR";

export const GET_IMPORTER = "GET_IMPORTER";
export const GET_IMPORTER_SUCCESS = "GET_IMPORTER_SUCCESS";
export const GET_IMPORTER_ERROR = "GET_IMPORTER_ERROR";

export const PUT_IMPORT_TAKEDOWN = "PUT_IMPORT_TAKEDOWN";
export const PUT_IMPORT_TAKEDOWN_SUCCESS = "PUT_IMPORT_TAKEDOWN_SUCCESS";
export const PUT_IMPORT_TAKEDOWN_ERROR = "PUT_IMPORT_TAKEDOWN_ERROR";
