const navMixin = {
  computed: {
    toHome() {
      return { name: "home", params: { locale: this.$i18n.locale } };
    },
    toHomeUser() {
      return { name: "home.user", params: { locale: this.$i18n.locale } };
    },
    toLogin() {
      return { name: "auth.login", params: { locale: this.$i18n.locale } };
    },
    toCatalog() {
      return {
        name: "catalog.list",
        params: { locale: this.$i18n.locale }
      };
    },
    toEpisodics() {
      return {
        name: "catalog.episodic.list",
        params: { locale: this.$i18n.locale }
      };
    },
    toLongFormats() {
      return {
        name: "catalog.longFormats.list",
        params: { locale: this.$i18n.locale }
      };
    },
    toStreams() {
      return {
        name: "catalog.streams",
        params: { locale: this.$i18n.locale }
      };
    },
    toImports() {
      return {
        name: "catalog.import.list",
        params: { locale: this.$i18n.locale }
      };
    }
  }
};

export { navMixin };
