// user teams
export const USER_TEAMS_REQUEST_ERROR = "USER_TEAMS_REQUEST_ERROR";
export const USER_TEAMS_REQUEST = "USER_TEAMS_REQUEST";
export const USER_TEAMS_REQUEST_SUCCESS = "USER_TEAMS_REQUEST_SUCCESS";

export const USER_ORGANISATION_GET_REQUEST = "USER_ORGANISATION_GET_REQUEST";
export const USER_ORGANISATION_GET_SUCCESS = "USER_ORGANISATION_GET_SUCCESS";
export const USER_ORGANISATION_GET_ERROR = "USER_ORGANISATION_GET_ERROR";

export const TEAM_JOIN_REQUEST = "TEAM_JOIN_REQUEST";
export const TEAM_JOIN_SUCCESS = "TEAM_JOIN_SUCCESS";
export const TEAM_JOIN_ERROR = "TEAM_JOIN_ERROR";

export const TEAM_JOIN_CREATE_FROM_ACCOUNT_REQUEST =
  "TEAM_JOIN_CREATE_FROM_ACCOUNT_REQUEST";
export const TEAM_JOIN_CREATE_FROM_ACCOUNT_SUCCESS =
  "TEAM_JOIN_CREATE_FROM_ACCOUNT_SUCCESS";
export const TEAM_JOIN_CREATE_FROM_ACCOUNT_ERROR =
  "TEAM_JOIN_CREATE_FROM_ACCOUNT_ERROR";

export const TEAM_MEMBERSHIP_DELETE_REQUEST = "TEAM_MEMBERSHIP_DELETE_REQUEST";
export const TEAM_MEMBERSHIP_DELETE_SUCCESS = "TEAM_MEMBERSHIP_DELETE_SUCCESS";
export const TEAM_MEMBERSHIP_DELETE_ERROR = "TEAM_MEMBERSHIP_DELETE_ERROR";

export const TEAM_MEMBERSHIP_LIST_REQUEST = "TEAM_MEMBERSHIP_LIST_REQUEST";
export const TEAM_MEMBERSHIP_LIST_SUCCESS = "TEAM_MEMBERSHIP_LIST_SUCCESS";
export const TEAM_MEMBERSHIP_LIST_ERROR = "TEAM_MEMBERSHIP_LIST_ERROR";

export const TEAM_MEMBER_INVITE = "TEAM_MEMBER_INVITE";
export const TEAM_MEMBER_INVITE_SUCCESS = "TEAM_MEMBER_INVITE_SUCCESS";
export const TEAM_MEMBER_INVITE_ERROR = "TEAM_MEMBER_INVITE_ERROR";

export const TEAM_USER_SET_STATUS_REQUEST = "TEAM_USER_SET_STATUS_REQUEST";
export const TEAM_USER_SET_STATUS_SUCCESS = "TEAM_USER_SET_STATUS_SUCCESS";
export const TEAM_USER_SET_STATUS_ERROR = "TEAM_USER_SET_STATUS_ERROR";

export const TEAM_ROLE_UPDATE_REQUEST = "TEAM_ROLE_UPDATE_REQUEST";
export const TEAM_ROLE_UPDATE_SUCCESS = "TEAM_ROLE_UPDATE_SUCCESS";
export const TEAM_ROLE_UPDATE_ERROR = "TEAM_ROLE_UPDATE_ERROR";

export const TEAM_SWITCH = "TEAM_SWITCH";
export const TEAM_RESET = "TEAM_RESET";

export const GET_ORG_TEAMS = "GET_ORG_TEAMS";
export const GET_ORG_TEAMS_SUCCESS = "GET_ORG_TEAMS_SUCCESS";
export const GET_ORG_TEAMS_ERROR = "GET_ORG_TEAMS_ERROR";

export const GET_ORG_TEAMS_APPEND_NEXT = "GET_ORG_TEAMS_APPEND_NEXT";
export const GET_ORG_TEAMS_APPEND_NEXT_SUCCESS =
  "GET_ORG_TEAMS_APPEND_NEXT_SUCCESS";
export const GET_ORG_TEAMS_APPEND_NEXT_ERROR =
  "GET_ORG_TEAMS_APPEND_NEXT_ERROR";

export const SET_ORG_TEAM = "SET_ORG_TEAM";

export const GET_TEAM_INFO = "GET_TEAM_INFO";
export const GET_TEAM_INFO_SUCCESS = "GET_TEAM_INFO_SUCCESS";
export const GET_TEAM_INFO_ERROR = "GET_TEAM_INFO_ERROR";

export const TEAM_MEMBERSHIP_UPDATE_REQUEST = "TEAM_MEMBERSHIP_UPDATE_REQUEST";
export const TEAM_MEMBERSHIP_UPDATE_SUCCESS = "TEAM_MEMBERSHIP_UPDATE_SUCCESS";
export const TEAM_MEMBERSHIP_UPDATE_ERROR = "TEAM_MEMBERSHIP_UPDATE_ERROR";

export const TEAM_MEMBERSHIP_MAKE_DEFAULT_REQUEST =
  "TEAM_MEMBERSHIP_MAKE_DEFAULT_REQUEST";
export const TEAM_MEMBERSHIP_MAKE_DEFAULT_SUCCESS =
  "TEAM_MEMBERSHIP_MAKE_DEFAULT_SUCCESS";
export const TEAM_MEMBERSHIP_MAKE_DEFAULT_ERROR =
  "TEAM_MEMBERSHIP_MAKE_DEFAULT_ERROR";

export const TEAM_USER_INVITE_APPROVE_REQUEST =
  "TEAM_USER_INVITE_APPROVE_REQUEST";

export const TEAM_USER_INVITE_APPROVE_SUCCESS =
  "TEAM_USER_INVITE_APPROVE_SUCCESS";
export const TEAM_USER_INVITE_APPROVE_ERROR = "TEAM_USER_INVITE_APPROVE_ERROR";

export const TEAM_USER_INVITE_DELETE_REQUEST =
  "TEAM_USER_INVITE_DELETE_REQUEST";
export const TEAM_USER_INVITE_DELETE_SUCCESS =
  "TEAM_USER_INVITE_DELETE_SUCCESS";
export const TEAM_USER_INVITE_DELETE_ERROR = "TEAM_USER_INVITE_DELETE_ERROR";
