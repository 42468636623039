import { ifAuthenticated, ifUserInTeam } from "#/stores/auth";
import i18n from "#/locales";
import store from "#/stores";

const routes = [
  {
    path: "fastChannels",
    beforeEnter: ifAuthenticated,
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        name: "home.user",
        beforeEnter: ifUserInTeam,
        component: () => import("#/pages/fastChannels/HomeUser"),
        meta: {
          title: "pageMeta.home.title"
        }
      },
      {
        name: "fastChannel.detail",
        path: ":slug",
        beforeEnter: ifUserInTeam,
        component: () => import("#/pages/fastChannels/Detail"),
        meta: {
          title: "pageMeta.fast_channel.title",

          breadcrumb: {
            crumbs: [
              {
                text: (route) =>
                  store.getters.getFastChannel(route.params.slug)?.name,
                active: true
              }
            ]
          }
        }
      },
      {
        name: "fastChannel.monitor",
        path: ":slug/monitor",
        beforeEnter: ifUserInTeam,
        props: (route) => ({ query: route.query.day }),
        component: () => import("#/pages/fastChannels/Monitor"),
        meta: {
          title: "pageMeta.monitor.title",
          class: "rc-grid-fit-viewport",
          breadcrumb: {
            crumbs: [
              {
                text: (route) =>
                  store.getters.getFastChannel(route.params.slug)?.name,
                fnTo: (route) => {
                  return {
                    name: "fastChannel.detail",
                    params: { slug: route.params.slug }
                  };
                }
              },
              {
                text: () => i18n.t("common.monitor"),
                active: true
              }
            ]
          }
        }
      },
      {
        path: ":slug/schedule",
        name: "fastChannel.week",
        beforeEnter: ifUserInTeam,
        props: (route) => ({ query: route.query.week }),
        component: () => import("#/pages/fastChannels/Schedule"),
        meta: {
          title: "pageMeta.schedule.title",
          class: "rc-grid-fit-viewport",
          breadcrumb: {
            crumbs: [
              {
                text: (route) =>
                  store.getters.getFastChannel(route.params.slug)?.name,
                fnTo: (route) => {
                  return {
                    name: "fastChannel.detail",
                    params: { slug: route.params.slug }
                  };
                }
              },
              {
                text: () => i18n.t("schedule.title"),
                active: true
              }
            ]
          }
        }
      }
    ]
  }
];
export default routes;
