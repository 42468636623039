<template>
  <main>
    <router-view :key="$route.fullPath" />
  </main>
</template>

<script>
export default {
  name: "RcSiteContent"
};
</script>
