import Notifications from "./Notifications.vue";
import { uniqueId } from "lodash";

const Notify = {
  items: [],

  showItem(item) {
    this.items.push({
      type: item.type,
      message: item.message,
      id: uniqueId()
    });
  },

  install(Vue) {
    Vue.component("RcNotifications", Notifications);

    Vue.prototype.$notify = {
      success: (message) => {
        this.showItem({ type: "success", message });
      },
      danger: (message) => {
        this.showItem({ type: "danger", message });
      },
      warning: (message) => {
        this.showItem({ type: "warning", message });
      },
      info: (message) => {
        this.showItem({ type: "info", message });
      },
      primary: (message) => {
        this.showItem({ type: "primary", message });
      },
      secondary: (message) => {
        this.showItem({ type: "secondary", message });
      },
      light: (message) => {
        this.showItem({ type: "light", message });
      },
      dark: (message) => {
        this.showItem({ type: "dark", message });
      },
      getItems: () => this.items
    };
  }
};

export default Notify;
