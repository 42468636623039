import { ifUserKnown } from "#/stores/auth";

function load(view) {
  return () =>
    import(/* webpackChunkName: "view-[request]" */ `#/pages/${view}.vue`);
}

const routes = [
  {
    name: "home",
    path: "/",
    beforeEnter: ifUserKnown,
    component: () => import("#/pages/auth/Login"),
    meta: {
      title: "pageMeta.home.title",
      cssClass: "rc-home-page"
    }
  },
  {
    path: "welcome",
    alias: "bienvenue",
    name: "welcome",
    component: load("market/Home"),
    beforeEnter: ifUserKnown,
    meta: {
      title: "pageMeta.home.title",
      alias: [null, null, "bienvenue"]
    }
  },
  {
    path: "not-found",
    name: "not.found",
    alias: "page-non-trouve",
    component: load("market/NotFound"),
    meta: {
      alias: [null, null, "page-non-trouve"],
      title: "pageMeta.notfound.title"
    }
  },
  {
    path: "ds",
    component: load("market/DesignSystem"),
    name: "market.designSystem",
    meta: {
      title: "Design System"
    }
  }
];
export default routes;
