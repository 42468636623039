export const LIB_SELECTABLES_SET = "LIB_SELECTABLES_SET";
export const LIB_DRAWER_SET_STATE = "LIB_DRAWER_SET_STATE";
export const LIB_FILTERS_SET = "LIB_FILTERS_SET";
export const LIB_FILTER_RESET = "LIB_FILTER_RESET";

export const LIB_SEARCH_SET = "LIB_SEARCH_SET";
export const LIB_KEYWORD_SET = "LIB_KEYWORD_SET";

export const LIB_AUTO_REFRESH_SET = "LIB_AUTO_REFRESH_SET";
export const LIB_MULTISELECT_SET = "LIB_MULTISELECT_SET";
export const LIB_COLLAPSE_SET_STATE = "LIB_COLLAPSE_SET_STATE";

export const LIB_TOGGLE_SHOW_SELECTED = "LIB_TOGGLE_SHOW_SELECTED";
export const LIB_TOGGLE_SHOW_SELECTED_SUCCESS =
  "LIB_TOGGLE_SHOW_SELECTED_SUCCESS";
export const LIB_TOGGLE_SHOW_SELECTED_ERROR = "LIB_TOGGLE_SHOW_SELECTED_ERROR";
