<template>
  <div class="notifications">
    <transition-group name="list" tag="div">
      <Notification
        v-for="item in items"
        :key="item.id"
        :item="item"
        @close="removeItem"
        >{{ item }}</Notification
      >
    </transition-group>
  </div>
</template>

<script>
import Notification from "./Notification.vue";

export default {
  components: {
    Notification
  },
  data() {
    return {
      items: []
    };
  },
  created() {
    this.items = this.$notify.getItems();
  },
  methods: {
    removeItem(item) {
      const key = this.items.indexOf(item);
      if (key >= 0) {
        this.items.splice(key, 1);
      }
    }
  }
};
</script>

<style lang="scss">
.notifications {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9999;
  max-width: 350px;
  width: 100%;

  z-index: 9999;

  .list-enter-active,
  .list-leave-active {
    transition: opacity 0.3s;
  }

  .list-enter,
  .list-leave-active {
    opacity: 0;
  }
}
</style>
