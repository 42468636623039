// user set full name
export const USER_SET_PROFILE_REQUEST = "USER_SET_PROFILE_REQUEST";
export const USER_SET_PROFILE_REQUEST_SUCCESS =
  "USER_SET_PROFILE_REQUEST_SUCCESS";
export const USER_SET_PROFILE_REQUEST_ERROR = "USER_SET_PROFILE_REQUEST_ERROR";

export const USER_ORGANISATION_PATCH_REQUEST =
  "USER_ORGANISATION_PATCH_REQUEST";
export const USER_ORGANISATION_PATCH_REQUEST_SUCCESS =
  "USER_ORGANISATION_PATCH_REQUEST_SUCCESS";
export const USER_ORGANISATION_PATCH_REQUEST_ERROR =
  "USER_ORGANISATION_PATCH_REQUEST_ERROR";

export const USER_ORGANISATION_REQUEST = "USER_ORGANISATION_REQUEST";
export const USER_ORGANISATION_REQUEST_SUCCESS =
  "USER_ORGANISATION_REQUEST_SUCCESS";
export const USER_ORGANISATION_REQUEST_ERROR =
  "USER_ORGANISATION_REQUEST_ERROR";

export const USER_GET_PROFILE_REQUEST = "USER_GET_PROFILE_REQUEST";
export const USER_GET_PROFILE_REQUEST_SUCCESS =
  "USER_GET_PROFILE_REQUEST_SUCCESS";
export const USER_GET_PROFILE_REQUEST_ERROR = "USER_GET_PROFILE_REQUEST_ERROR";
