import { ifAuthenticated, ifUserInTeam } from "#/stores/auth";

import i18n from "#/locales";
import store from "#/stores";

const routes = [
  {
    path: "teams",
    alias: "equipes",
    beforeEnter: ifAuthenticated,
    component: {
      render(c) {
        return c("router-view");
      }
    },

    children: [
      {
        path: "",
        name: "user.account.team.join",
        component: () => import("@/libvue/apps/team/components/OrgTeams.vue"),
        meta: {
          alias: [null, null, "equipes"],
          title: "pageMeta.catalog.title",
          breadcrumb: {
            crumbs: [
              {
                text: () => i18n.tc("team.team", 2),
                active: true
              }
            ]
          }
        }
      },
      {
        path: ":uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/memberships",
        alias:
          ":uid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/membres",
        beforeEnter: ifUserInTeam,
        name: "user.account.team.users",
        component: () =>
          import("@/libvue/apps/team/components/Memberships.vue"),
        meta: {
          alias: [null, null, "equipes", null, "membres"],
          breadcrumb: {
            crumbs: [
              {
                text: () => i18n.tc("team.team", 2),
                to: { name: "user.account.team.join" }
              },
              {
                text: () => store.getters.getProfile.team_name,
                active: true
              }
            ]
          }
        }
      }
    ]
  }
];

export default routes;
