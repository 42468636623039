// Vue plugins
import Vue from "vue";
import VueProgressBar from "vue-progressbar";
import VueRx from "vue-rx";
import routes from "#/routes";
import Scopes from "@/utils/scopes";
import rcPageStats from "@/plugins/statistics/pageStats";
import Config from "#/config";
import stores from "#/stores";
import BootstrapVue from "bootstrap-vue";
import Notify from "@/plugins/notify";

Vue.use(Notify);
Vue.use(BootstrapVue);
Vue.use(VueProgressBar, Config.plugins.VueProgressBar);
Vue.use(VueRx);
Vue.use(Scopes);

if (Config.ENVIRONMENT == "production") {
  Vue.use(rcPageStats, { router: routes, stores });
}
