export const ROOT_UPDATE_PAGE_LOADED = "ROOT_UPDATE_PAGE_LOADED";
export const ROOT_RESET_ALL_STATES = "ROOT_RESET_ALL_STATES";
export const ROOT_SHOW_CONFIRMATION_MODAL = "ROOT_SHOW_CONFIRMATION_MODAL";
export const ROOT_UPDATE_IS_PREVIEW_PANE_OPENABLE =
  "ROOT_UPDATE_IS_PREVIEW_PANE_OPENABLE";
export const ROOT_SHOW_MOBILE_MENU = "ROOT_SHOW_MOBILE_MENU";
export const ROOT_HIDE_MOBILE_MENU = "ROOT_HIDE_MOBILE_MENU";
export const ROOT_UPDATE_IF_TOUCH_DEVICE = "ROOT_UPDATE_IF_TOUCH_DEVICE";
export const ROOT_SET_ONBOARDING_COMP = "ROOT_SET_ONBOARDING_COMP";

import { AUTH_RESET } from "@/libvue/apps/auth/actions";
import { TEAM_RESET } from "@/libvue/apps/team/actions";

function getDefaultState() {
  return {
    errors: {},
    status: "",
    isPageLoaded: false,
    isPreviewPaneOpenable: true,
    isMobileMenuVisible: false,
    isUserOnATouchDevice: false,
    onboard: {
      currentComponent: "rc-roles"
    },
    preferedSite: ""
  };
}

const getters = {};

const actions = {
  [ROOT_UPDATE_PAGE_LOADED]: ({ commit }, isPageLoaded) => {
    commit(ROOT_UPDATE_PAGE_LOADED, isPageLoaded);
  },
  [ROOT_UPDATE_IS_PREVIEW_PANE_OPENABLE]: (
    { commit },
    isPreviewPaneOpenable
  ) => {
    commit(ROOT_UPDATE_IS_PREVIEW_PANE_OPENABLE, isPreviewPaneOpenable);
  },
  [ROOT_RESET_ALL_STATES]: ({ commit }) => {
    commit("RESET");
    commit(AUTH_RESET);
    commit(TEAM_RESET);
    commit(ROOT_SET_ONBOARDING_COMP, "rc-roles");
  },
  //   [ROOT_SHOW_CONFIRMATION_MODAL]: ({ dispatch }) => {
  //     dispatch(MODAL_OPEN, { id: "confirmationModal" });
  //   },
  [ROOT_SHOW_MOBILE_MENU]: ({ commit }) => {
    commit(ROOT_SHOW_MOBILE_MENU);
  },
  [ROOT_HIDE_MOBILE_MENU]: ({ commit }) => {
    commit(ROOT_HIDE_MOBILE_MENU);
  },
  [ROOT_UPDATE_IF_TOUCH_DEVICE]: ({ commit }, isUserOnATouchDevice) => {
    commit(ROOT_UPDATE_IF_TOUCH_DEVICE, isUserOnATouchDevice);
  }
};

const mutations = {
  [ROOT_UPDATE_PAGE_LOADED]: (state, isPageLoaded) => {
    state.isPageLoaded = isPageLoaded;
  },
  [ROOT_UPDATE_IS_PREVIEW_PANE_OPENABLE]: (state, isPreviewPaneOpenable) => {
    state.isPreviewPaneOpenable = isPreviewPaneOpenable;
  },
  [ROOT_SHOW_MOBILE_MENU]: (state) => {
    state.isMobileMenuVisible = true;
  },
  [ROOT_HIDE_MOBILE_MENU]: (state) => {
    state.isMobileMenuVisible = false;
  },
  [ROOT_UPDATE_IF_TOUCH_DEVICE]: (state, isUserOnATouchDevice) => {
    state.isUserOnATouchDevice = isUserOnATouchDevice;
  },
  [ROOT_SET_ONBOARDING_COMP]: (state, currentComponent) => {
    state.onboard.currentComponent = currentComponent;
  },
  ["RESET"]: (state) => {
    const defaultState = getDefaultState();
    Object.keys(defaultState).forEach((key) => {
      state[key] = defaultState[key];
    });
  }
};

export default {
  state: getDefaultState,
  getters,
  actions,
  mutations
};
