import { render, staticRenderFns } from "./JoinedTeams.vue?vue&type=template&id=ad8656a6&scoped=true"
import script from "./JoinedTeams.vue?vue&type=script&lang=js"
export * from "./JoinedTeams.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad8656a6",
  null
  
)

export default component.exports