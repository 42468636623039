import { ifAuthenticated } from "#/stores/auth";
import i18n from "#/locales";

const routes = [
  {
    path: "bookmarks",
    alias: "favoris",
    name: "user.favorites",
    component: () => import(`#/pages/catalog/FavoriteList.vue`),
    beforeEnter: ifAuthenticated,
    meta: {
      alias: [null, null, "favoris"],
      title: "pageMeta.favorites.title",
      breadcrumb: {
        crumbs: [
          {
            text: () => i18n.t("catalog.favorites.my"),
            active: true
          }
        ]
      }
    }
  }
];
export default routes;
